import { createGlobalStyle } from 'styled-components';
import {
  fontSizes,
  baseColors,
  E_FONT_WEIGHT,
  E_FONT_FAMILY,
  colors,
} from './index';

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  ul,
  ol {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  body {
    font-family: ${E_FONT_FAMILY.DEFAULT};
    font-size: ${fontSizes[2]};
    font-weight: ${E_FONT_WEIGHT.REGULAR};
    color: ${baseColors.black};
    line-height: 1.5;
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    letter-spacing: 0.5px;
    padding:
    env(safe-area-inset-top, 10px)
    env(safe-area-inset-right, 10px)
    env(safe-area-inset-bottom, 10px)
    env(safe-area-inset-left, 10px);
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    body {
      overflow-x: hidden;
    }
  }


  h1,
  h2
   {
    font-family: ${E_FONT_FAMILY.TITLE};
    font-weight: ${E_FONT_WEIGHT.MEDIUM};
    line-height: 1.25;
    letter-spacing: 0.8px;
  }

  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    line-height: 1.25;
    font-family: inherit;
  }

  img {
    max-width: 100%;
    display: inline-block;
  }

  a {
    display: inline-block;
    color: inherit;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }

  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  article > * + * {
    margin-top: 1em;
  }

  input,
  button,
  textarea,
  select {
    font-size: inherit;
    line-height: normal;
    outline: none;
  }

  button {
    padding: 0;
    color: inherit;
    background: none;
    border: none;
    line-height: inherit;
    appearance: none;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: inherit;

    &:hover {
      cursor: pointer;
    }
  }

  input,
  textarea {
    appearance: none;
    border: none;
  }

  input::placeholder,
  textarea::placeholder {
    color: inherit;
  }

  fieldset {
    border: 0;
    padding: 0;
  }

  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  #__next {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  @keyframes rotate {
    from { transform: rotate(0deg);   }
    to   { transform: rotate(360deg); }
  }

  html.olark-disable-back-scroll body.olark-disable-back-scroll {
    overflow: initial !important;
  }

  body #olark-wrapper .olark-launch-button {
    background-color: ${colors.primary} !important;
    color: ${baseColors.white} !important;
    min-height: 50px !important;
  }
  
  body #olark-wrapper .olark-launch-button .olark-close-icon path {
    stroke: ${baseColors.white} !important;
  }
`;
