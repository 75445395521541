import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';
import { ExternalLink } from '@hotelplan/platform-graphql-api';

export const AgencyContactFormModal = styled(WhiteBoxModal).attrs({
  name: 'agencyContactForm',
  title: '',
  fullScreen: true,
})(({ theme }) => ({
  '.modal-wrapper': theme.modals.wideModalWrapper,
  '.modal-body': theme.modals.commonModalBody,

  '&.iframed': {
    '.shadow-overlay-children .modal-wrapper': {
      height: '100%',
      iframe: {
        height: '100%',
        border: 0,
      },
    },
    '.shadow-overlay-children .modal-body': {
      padding: 0,
      '.form-all': {
        marginTop: 0,
      },
    },
  },
}));

type TProps = {
  closeModal(): void;
  link: ExternalLink;
};

const AgencyAppoinment: React.FC<TProps> = ({ closeModal, link }: TProps) => {
  const handleCloseModal = () => {
    requestAnimationFrame(() => {
      closeModal();
    });
  };
  const [t] = useTranslation('agency');

  return (
    <>
      <AgencyContactFormModal
        show={true}
        onClose={handleCloseModal}
        className={link ? 'iframed' : ''}
      >
        <iframe src={link.url} title={t('common:openingHours.signup')} />
      </AgencyContactFormModal>
    </>
  );
};

export default AgencyAppoinment;
