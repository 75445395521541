import React from 'react';
import styled from 'styled-components';
import { FooterHeading } from '@hotelplan/components.common.footer';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export interface IReasonsOfConfidenceProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  text: string;
}

const ROCWrapper = styled.div(
  sx2CssThemeFn({
    p: 4,
    flexBasis: [null, '50%'],
    mr: [null, 4],
    backgroundColor: 'background',
  })
);

const ROCContent = styled.div(({ theme, theme: { space, media } }) => ({
  listStyle: 'none',
  paddingTop: '24px',
  'li:last-child': {
    marginBottom: 0,
  },
  li: {
    paddingLeft: '30px',
    marginBottom: space[3],
    position: 'relative',
    fontSize: '16px',
    lineHeight: '24px',
    '&:before': {
      content: '""',
      position: 'absolute',
      color: 'black',
      left: 0,
      width: '22px',
      height: '22px',
      backgroundImage: 'url("/images/svg/Success.svg")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    [media.tablet]: {
      paddingLeft: '35px',
      fontSize: '18px',
      lineHeight: '28px',
    },
  },
  p: {
    marginBottom: space[1],
    [media.tablet]: {
      marginBottom: space[2],
    },
  },
  a: theme.link.text,
}));

const ReasonsOfConfidence: React.FC<IReasonsOfConfidenceProps> = ({
  title,
  text,
  className,
  ...divProps
}) => {
  return (
    <ROCWrapper
      {...divProps}
      data-id="reasons-of-confidence"
      className={className}
    >
      {title && <FooterHeading data-id="title">{title}</FooterHeading>}
      <ROCContent
        data-id="content"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </ROCWrapper>
  );
};

export default ReasonsOfConfidence;
