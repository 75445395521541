import { useMetaModulesContext } from '@hotelplan/components.common.meta-modules';
import { PageType } from '@hotelplan/graphql.types';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { useGetBoostersQuery } from 'graphql/boosters/GetBoosters.generated';

export function useGetBoostersData() {
  const { id } = useObjectId();
  const pageType = usePageType();
  const { hideBoosters } = useMetaModulesContext();

  const { data, loading } = useGetBoostersQuery({
    skip: !pageType || hideBoosters,
    variables: {
      objectId: id || undefined,
      pageType: pageType as PageType,
    },
  });

  return {
    boosters: data?.boosters || [],
    loading,
  };
}
