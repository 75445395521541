import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from '@hotelplan/components.common.link';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';
import { track } from '@hotelplan/libs.tracking-events';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const FooterFeedbackBoxWrapper = styled.div(
  sx2CssThemeFn({
    backgroundColor: 'white',
    mt: [3, 4],
    p: 4,
  })
);

const Title = styled.h3(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn(FONT_SIZE.S)
);

const FormLink = styled(Link).attrs({
  icon: {
    name: 'message-with-star',
  },
})(({ theme: { icons, FONT_SIZE, colors } }) =>
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    ...FONT_SIZE.LINK_LARGE,
    cursor: 'pointer',
    pt: 4,
    textDecoration: 'underline',
    textDecorationColor: colors.linkUnderlineColor,
    textUnderlineOffset: '6px',
    transition: 'none',
    transform: 'translate(0)',
    '&:hover,&:focus': {
      color: colors.defaultText,
      textDecorationColor: colors.defaultText,
      transform: 'scale(1)',
    },
    '.icon': { ...icons.lg, color: 'black', mr: 2, flexShrink: 0 },
  })
);

const FeedbackBoxModal = styled(WhiteBoxModal).attrs({
  name: 'FeedbackBoxModal',
})(
  sx2CssThemeFn({
    '.modal-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      maxWidth: ['initial', '572px'],
      maxHeight: ['80vh', '600px'],
      height: '100%',
      borderRadius: 'unset',
      padding: 0,
      mx: [3, 0],
      mb: ['20%', 0],
      overflowY: 'auto',
    },
    '.modal-header': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      borderBottom: 'none',
      zIndex: 1,
    },
  })
);

const ResponsiveIFrame = styled.iframe(
  sx2CssThemeFn({
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    border: 'none',
  })
);

const trackOpenFeedbackBoxClick = () => {
  track({
    event: `🚀 click.feedback`,
    eventMetadata: {
      source: `footer`,
    },
    _clear: true,
  });
};

const FooterFeedbackBox = () => {
  const { t } = useTranslation('common');
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <FooterFeedbackBoxWrapper>
      <Title>{t('common:footer_feedback_box.title')}</Title>
      <FormLink
        onClick={() => {
          trackOpenFeedbackBoxClick();
          setIsModalOpen(true);
        }}
      >
        {t('common:footer_feedback_box.linkName')}
      </FormLink>
      <FeedbackBoxModal
        show={isModalOpen}
        title=""
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <ResponsiveIFrame
          title={`footer_feedback_box`}
          src={t('common:footer_feedback_box.link')}
          width="100%"
        />
      </FeedbackBoxModal>
    </FooterFeedbackBoxWrapper>
  );
};

export default FooterFeedbackBox;
