import React from 'react';
import { InfoNotification } from '@hotelplan/components.common.notifications';
import { useGetInfoNotificationQuery } from 'graphql/notifications/GetInfoNotification.generated';

const InfoNotificationBanner = () => {
  const { data } = useGetInfoNotificationQuery({});

  const mainText = data?.notificationInfoData.mainText;
  const icon = data?.notificationInfoData.icon;

  return mainText ? <InfoNotification mainText={mainText} icon={icon} /> : null;
};

export default React.memo(InfoNotificationBanner);
