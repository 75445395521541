import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetAgencyContactQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetAgencyContactQuery = {
  __typename?: 'Query';
  agencyContact: {
    __typename?: 'AgencyContact';
    title: string;
    timezone: string;
    appointmentLink?: {
      __typename?: 'ExternalLink';
      caption?: string | null;
      openMethod: Types.OpenLinkMethod;
      url: string;
    } | null;
    addressDetails: {
      __typename?: 'AddressDetails';
      zip?: string | null;
      street?: string | null;
      city?: string | null;
      region?: string | null;
      country?: string | null;
    };
    openingHours: Array<{
      __typename?: 'OpeningHours';
      open: boolean;
      shifts: Array<{ __typename?: 'Shift'; from: string; to: string }>;
    }>;
    phone: {
      __typename?: 'AgencyContactPhone';
      title?: string | null;
      link: {
        __typename?: 'ExternalLink';
        url: string;
        openMethod: Types.OpenLinkMethod;
        caption?: string | null;
      };
    };
    emailLink: {
      __typename?: 'ExternalLink';
      url: string;
      openMethod: Types.OpenLinkMethod;
      caption?: string | null;
    };
  };
};

export const GetAgencyContactDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAgencyContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agencyContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointmentLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'openMethod' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'street' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'region' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingHours' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'open' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shifts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'from' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'to' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phone' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openMethod' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'openMethod' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;

/**
 * __useGetAgencyContactQuery__
 *
 * To run a query within a React component, call `useGetAgencyContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgencyContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgencyContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetAgencyContactQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAgencyContactQuery,
    GetAgencyContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetAgencyContactQuery,
    GetAgencyContactQueryVariables
  >(GetAgencyContactDocument, options);
}
export function useGetAgencyContactLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAgencyContactQuery,
    GetAgencyContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAgencyContactQuery,
    GetAgencyContactQueryVariables
  >(GetAgencyContactDocument, options);
}
export type GetAgencyContactQueryHookResult = ReturnType<
  typeof useGetAgencyContactQuery
>;
export type GetAgencyContactLazyQueryHookResult = ReturnType<
  typeof useGetAgencyContactLazyQuery
>;
export type GetAgencyContactQueryResult = Apollo.QueryResult<
  GetAgencyContactQuery,
  GetAgencyContactQueryVariables
>;
