export enum FeatureList {
  BD4TRAVEL_SRL_SORTING = 'bd4travel-srl-sorting',
  MY_ACCOUNT_BOOKING_CANCEL = 'myaccount-booking-cancel',
  MY_ACCOUNT_UPSELL_TRANSFERS = 'myaccount-upsell-transfers',
  SHOW_EXTENDED_OFFER_DETAILS = 'show-extended-offer-details',
  HOLIDAY_FINDER_CONTEST_TEASER = 'holiday-finder-contest-teaser',
  HOLIDAY_FINDER_CONTEST_INFO = 'holiday-finder-contest-info',
  HOLIDAY_FINDER_CONTEST_WISHLIST = 'holiday-finder-contest-wishlist',
  SRL_SINGLE_ONLY = 'srl-single-only',
  SRL_NAVIGATION = 'srl-navigation',
  META_SEARCH_ORL_BEHAVIOR = 'hotelads-behavior',
  PDP_PRICE_DATE_OVERVIEW_BEHAVIOUR = 'pdp-price-date-overview-behaviour',
  CUSTOMER_SUPPORT_ELEMENT = 'customer-support-element',
  CUSTOM_GLOBAL_DEFAULT_DURATIONS_PACKAGE = 'custom-global-default-durations-package',
  CUSTOMER_SUPPORT_BOOSTER = 'customer-support-booster',
  HP_SRL_POPULAR_FILTERS = 'hp-srl-popular-filters',
  HP_PRODUCT_CARD_DESIGN = 'hp-product-card-design',
  HP_SORTING_ORL = 'hp-sorting-orl',
  HP_PDP_OVERVIEW_DESIGN = 'hp-pdp-overview-design',
  HP_HOME_OFFERS_TO_TOP = 'hp-home-offers-to-top',
  HP_PDP_POPULAR_ELEMENT = 'pdp-popular-element',
  TOP_DESTINATIONS_AUTOSUGGEST_PACKAGE = 'top-destinations-autosuggest-package',
  TOP_DESTINATIONS_AUTOSUGGEST_HOTEL = 'top-destinations-autosuggest-hotel',
  POPULAR_FILTER_ON_TOP = 'hp-popular-filters-on-top',
  CMS_HP_GEO_PAGE_COMPONENTS_BASED_STRUCTURE = 'cms-hp-geo-page-components-based-structure',
  AUTOSUGGEST_HOTEL_ONLY_DESTINATIONS = 'autosuggest-hotel-only-destinations',
  HP_SEARCH_CONTROL_CALENDAR_VARIANTS = 'hp-search-control-calendar-variants',
  MEIN_KONTO = 'mein-konto-features',
  HP_ORL_SHOW_MORE = 'hp-orl-show-more',
  HP_MOST_ENGAGED_PRODUCT_ON_TOP = 'most-engaged-product-on-top',
  HP_LAST_SEEN_HOTELS = 'hp-last-seen-hotels',
  DESATURATION_FILTER = 'hp-desaturation-filter', // BE
  MAX_SEARCHING_ROOMS = 'hp-max-searching-rooms',
  SPLIT_PAGEVIEW_EVENT = 'split-pageview-event',
}
