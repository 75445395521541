import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type ViewedProductAddedToWishlistMutationVariables = Types.Exact<{
  productId: Types.Scalars['String'];
  context: Types.RequestContextInput;
}>;

export type ViewedProductAddedToWishlistMutation = {
  __typename?: 'Mutation';
  viewedProductAddedToWishlist: {
    __typename?: 'ViewedProductHistoryResponse';
    message?: string | null;
  };
};

export const ViewedProductAddedToWishlistDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ViewedProductAddedToWishlist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewedProductAddedToWishlist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export type ViewedProductAddedToWishlistMutationFn = Apollo.MutationFunction<
  ViewedProductAddedToWishlistMutation,
  ViewedProductAddedToWishlistMutationVariables
>;

/**
 * __useViewedProductAddedToWishlistMutation__
 *
 * To run a mutation, you first call `useViewedProductAddedToWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewedProductAddedToWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewedProductAddedToWishlistMutation, { data, loading, error }] = useViewedProductAddedToWishlistMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useViewedProductAddedToWishlistMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ViewedProductAddedToWishlistMutation,
    ViewedProductAddedToWishlistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ViewedProductAddedToWishlistMutation,
    ViewedProductAddedToWishlistMutationVariables
  >(ViewedProductAddedToWishlistDocument, options);
}
export type ViewedProductAddedToWishlistMutationHookResult = ReturnType<
  typeof useViewedProductAddedToWishlistMutation
>;
export type ViewedProductAddedToWishlistMutationResult = Apollo.MutationResult<ViewedProductAddedToWishlistMutation>;
export type ViewedProductAddedToWishlistMutationOptions = Apollo.BaseMutationOptions<
  ViewedProductAddedToWishlistMutation,
  ViewedProductAddedToWishlistMutationVariables
>;
