import dynamic from 'next/dynamic';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { DeviceType, useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Container } from 'components/domain/container';
import FooterSkeleton from 'components/domain/footer/Footer.skeleton';
import FooterContacts from 'components/domain/footer/FooterContacts';
import FooterFeedbackBox from 'components/domain/footer/FooterFeedbackBox';
import ReasonsOfConfidence from 'components/domain/footer/ReasonsOfConfidence';
import usePageFooter from 'components/domain/layout/usePageFooter';

const FooterBottomDesktop = dynamic<{}>(
  () => import('components/domain/footer/FooterBottomDesktop')
);

const FooterBottomMobile = dynamic<{}>(
  () => import('components/domain/footer/FooterBottomMobile')
);

const ReasonsOfConfidenceSection = styled(Container)(({ theme: { media } }) =>
  sx2CssThemeFn({
    marginBottom: 3,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    pt: 5,
    pb: 3,
    [media.tablet]: {
      marginBottom: 5,
      pt: 5,
      pb: 5,
      boxShadow: 'none',
      overflow: 'inherit',
      flexDirection: 'row',
    },
  })
);

const ReasonsOfConfidenceSectionWrapper = styled.div(
  sx2CssThemeFn({
    bg: 'lightGreySixth',
    px: [3, 0],
  })
);

const FooterBoxWrap = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '50%',
  })
);

const Footer: React.FC = () => {
  const { reasonsOfConfidence, contacts, loading } = usePageFooter();

  const { serverType, mobile } = useDeviceType();

  const isNotDesktop = serverType !== DeviceType.Desktop;

  const footerBottom = useMemo(() => {
    const isMobileView = typeof window === 'undefined' ? isNotDesktop : mobile;
    return isMobileView ? <FooterBottomMobile /> : <FooterBottomDesktop />;
  }, [mobile, isNotDesktop]);

  return (
    <footer data-id="footer" id="footer">
      {loading ? (
        <FooterSkeleton />
      ) : (
        <>
          {reasonsOfConfidence && contacts ? (
            <ReasonsOfConfidenceSectionWrapper>
              <ReasonsOfConfidenceSection>
                {reasonsOfConfidence && (
                  <ReasonsOfConfidence {...reasonsOfConfidence} />
                )}
                <FooterBoxWrap>
                  {contacts && <FooterContacts {...contacts} />}
                  <FooterFeedbackBox />
                </FooterBoxWrap>
              </ReasonsOfConfidenceSection>
            </ReasonsOfConfidenceSectionWrapper>
          ) : null}

          {footerBottom}
        </>
      )}
    </footer>
  );
};

export default React.memo(Footer);
