import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetInfoNotificationQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
}>;

export type GetInfoNotificationQuery = {
  __typename?: 'Query';
  notificationInfoData: {
    __typename?: 'NotificationInfo';
    mainText: string;
    icon: {
      __typename?: 'Image';
      alt: string;
      desaturated?: boolean | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
  };
};

export type InfoNotificationFragment = {
  __typename?: 'NotificationInfo';
  mainText: string;
  icon: {
    __typename?: 'Image';
    alt: string;
    desaturated?: boolean | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export const InfoNotificationFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'infoNotification' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NotificationInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'StringValue', value: '1', block: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const GetInfoNotificationDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInfoNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationInfoData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'infoNotification' },
                },
              ],
            },
          },
        ],
      },
    },
    ...InfoNotificationFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetInfoNotificationQuery__
 *
 * To run a query within a React component, call `useGetInfoNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfoNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfoNotificationQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetInfoNotificationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetInfoNotificationQuery,
    GetInfoNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetInfoNotificationQuery,
    GetInfoNotificationQueryVariables
  >(GetInfoNotificationDocument, options);
}
export function useGetInfoNotificationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetInfoNotificationQuery,
    GetInfoNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetInfoNotificationQuery,
    GetInfoNotificationQueryVariables
  >(GetInfoNotificationDocument, options);
}
export type GetInfoNotificationQueryHookResult = ReturnType<
  typeof useGetInfoNotificationQuery
>;
export type GetInfoNotificationLazyQueryHookResult = ReturnType<
  typeof useGetInfoNotificationLazyQuery
>;
export type GetInfoNotificationQueryResult = Apollo.QueryResult<
  GetInfoNotificationQuery,
  GetInfoNotificationQueryVariables
>;
