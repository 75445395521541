import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetPersonalProfileQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
}>;

export type GetPersonalProfileQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'AccountContext';
    personalData: {
      __typename?: 'AccountPersonalDataResponse';
      salutation?: Types.Salutation | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      dateOfBirth?: any | null;
      nationality?: string | null;
      agency?: string | null;
      addressDetails?: {
        __typename?: 'AddressDetails';
        street?: string | null;
        zip?: string | null;
        city?: string | null;
        country?: string | null;
      } | null;
      phoneDetails?: {
        __typename?: 'PhoneDetails';
        country?: string | null;
        phone?: string | null;
      } | null;
      mobileDetails?: {
        __typename?: 'PhoneDetails';
        country?: string | null;
        phone?: string | null;
      } | null;
    };
  };
};

export const GetPersonalProfileDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPersonalProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'personalData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salutation' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'street' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zip' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phone' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobileDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phone' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateOfBirth' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nationality' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agency' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;

/**
 * __useGetPersonalProfileQuery__
 *
 * To run a query within a React component, call `useGetPersonalProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalProfileQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetPersonalProfileQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPersonalProfileQuery,
    GetPersonalProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPersonalProfileQuery,
    GetPersonalProfileQueryVariables
  >(GetPersonalProfileDocument, options);
}
export function useGetPersonalProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPersonalProfileQuery,
    GetPersonalProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPersonalProfileQuery,
    GetPersonalProfileQueryVariables
  >(GetPersonalProfileDocument, options);
}
export type GetPersonalProfileQueryHookResult = ReturnType<
  typeof useGetPersonalProfileQuery
>;
export type GetPersonalProfileLazyQueryHookResult = ReturnType<
  typeof useGetPersonalProfileLazyQuery
>;
export type GetPersonalProfileQueryResult = Apollo.QueryResult<
  GetPersonalProfileQuery,
  GetPersonalProfileQueryVariables
>;
