import noop from 'lodash/noop';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { FeatureList } from 'config/pageConfig.constants';
import {
  IViewedProductContext,
  IViewedProductContextState,
  TViewedProductContextAction,
  ViewedProductContextActionType,
  ViewedProductEventType,
} from './ViewedProductContext.types';
import ViewedProductContextQueueItem from './ViewedProductContextQueueItem';

const initialViewedProductContextState: IViewedProductContextState = {
  queue: [],
};

const defaultViewedProductContext = {
  ...initialViewedProductContextState,
  trackVisited: noop,
  trackAddedToWishlist: noop,
  trackCheckout: noop,
};

export const ViewedProductContext = createContext<IViewedProductContext>(
  defaultViewedProductContext
);

function viewedProductContextReducer(
  prevState: IViewedProductContextState,
  action: TViewedProductContextAction
): IViewedProductContextState {
  switch (action.type) {
    case ViewedProductContextActionType.ADD:
      const queue = [...prevState.queue];

      queue.push(action.event);

      return {
        ...prevState,
        queue,
      };
    case ViewedProductContextActionType.REMOVE:
      return {
        ...prevState,
        queue: prevState.queue.filter(i => i.id != action.event.id),
      };
  }
}

export function ViewedProductContextProvider(
  props: PropsWithChildren<{}>
): ReturnType<FC> {
  const { isEnabled } = useFeatureToggle();
  const featureEnabled = isEnabled(FeatureList.HP_MOST_ENGAGED_PRODUCT_ON_TOP);

  const [state, dispatch] = useReducer(
    viewedProductContextReducer,
    initialViewedProductContextState
  );

  const handlers = useMemo(() => {
    return {
      trackVisited: (productId: string) => {
        dispatch({
          type: ViewedProductContextActionType.ADD,
          event: {
            id: uuidv4(),
            type: ViewedProductEventType.VISITED,
            productId: productId,
          },
        });
      },
      trackAddedToWishlist: (productId: string) => {
        dispatch({
          type: ViewedProductContextActionType.ADD,
          event: {
            id: uuidv4(),
            type: ViewedProductEventType.ADDED_TO_WISHLIST,
            productId: productId,
          },
        });
      },
      trackCheckout: (productId: string) => {
        dispatch({
          type: ViewedProductContextActionType.ADD,
          event: {
            id: uuidv4(),
            type: ViewedProductEventType.CHECKOUT,
            productId: productId,
          },
        });
      },
    };
  }, []);

  const context: IViewedProductContext = useMemo(
    () => ({
      ...state,
      ...handlers,
    }),
    [handlers, state]
  );

  return (
    <ViewedProductContext.Provider
      value={featureEnabled ? context : defaultViewedProductContext}
    >
      {state.queue.map(event => {
        return (
          <ViewedProductContextQueueItem
            key={event.id}
            event={event}
            dispatch={dispatch}
          />
        );
      })}
      {props.children}
    </ViewedProductContext.Provider>
  );
}

export function useViewedProductContext() {
  return useContext(ViewedProductContext) as IViewedProductContext;
}
