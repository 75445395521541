import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import {
  SkipLink,
  SkipLinksGroup,
} from '@hotelplan/components.common.skip-links';
import { useIsomorphicLayoutEffect } from '@hotelplan/libs.hooks-react';
import { SkipLinksTarget, trackSkipLinks } from '@hotelplan/libs.tracking';

export const CONTACT_OVERLAY_EVENT = 'contact-overlay-event';

const SkipLinksComponent = () => {
  const [t] = useTranslation('common');
  const [isShowSearchSkipLink, setIsShowSearchSkipLink] = useState(false);

  useIsomorphicLayoutEffect(() => {
    const searchEl = document.getElementById('search-form-wrap');
    if (searchEl) setIsShowSearchSkipLink(true);
  }, []);

  return (
    <SkipLinksGroup title={t('common:skip_links.title')}>
      {isShowSearchSkipLink ? (
        <SkipLink
          link="#search-form-wrap"
          onClick={() => {
            trackSkipLinks(SkipLinksTarget.SEARCH_FORM);
          }}
        >
          {t('common:skip_links.search')}
        </SkipLink>
      ) : null}
      <SkipLink
        link="#maincontent"
        onClick={() => {
          trackSkipLinks(SkipLinksTarget.MAIN_CONTENT);
        }}
      >
        {t('common:skip_links.maincontent')}
      </SkipLink>
      <SkipLink
        link="#footer"
        onClick={() => {
          trackSkipLinks(SkipLinksTarget.FOOTER);
        }}
      >
        {t('common:skip_links.footer')}
      </SkipLink>
      <SkipLink
        onClick={() => {
          window.dispatchEvent(new CustomEvent(CONTACT_OVERLAY_EVENT));
          trackSkipLinks(SkipLinksTarget.CONTACT);
        }}
        link="#contact"
      >
        {t('common:skip_links.contact')}
      </SkipLink>
    </SkipLinksGroup>
  );
};

export default SkipLinksComponent;
