import {
  registerRouteConfig,
  registerPageRoutes,
} from '@hotelplan/libs.router-config';
import { PageType } from '@hotelplan/graphql.types';

const routes = registerPageRoutes([
  {
    page: '/',
    pageType: PageType.Home,
    match: '/',
    locale: 'de',
    prefix: false,
  },
  {
    page: '/',
    pageType: PageType.Home,
    match: '/',
    locale: 'fr',
    prefix: true,
  },
]);

export default routes;

[
  [
    `/holidayfinder`,
    `/holidayfinder`,
    `/holiday-finder`,
    PageType.HolidayFinderLanding,
  ],
  [
    `/reisebuero-filiale`,
    `/agence-de-voyage`,
    `/agency-overview`,
    PageType.AgencyOverview,
  ],
  [
    `/reisebuero-filiale/:slug/:id`,
    `/agence-de-voyage/:slug/:id`,
    `/agency`,
    PageType.AgencyObject,
  ],
  [`/b2b/login`, `/b2b/login`, `/b2b-login`, PageType.B2BLogin],
  [`/kataloge`, `/catalogues`, `/catalogs`, PageType.CatalogOverview],
  [`/kontakt`, `/contact`, `/contact`, PageType.Contact],
  [
    `/email-permission/widerrufen`,
    `/email-permission/retracter`,
    `/email-revoke`,
    PageType.EmailAdvertisingRevocation,
  ],
  [
    `/email-permission/widerruf-erfolgreich`,
    `/email-permission/retractation-succes`,
    `/email-revoke-success`,
    PageType.EmailAdvertisingRevocationConfirmation,
  ],
  [`/flug`, `/vol`, `/flight`, PageType.FlightHome],
  [`/flug/suchen`, `/vol/chercher`, `/flight-search`, PageType.FlightSrl],
  [
    `/ferien/:params/:id([kgcrdf]-\\d+)`,
    `/vacances/:params/:id([kgcrdf]-\\d+)`,
    `/geo`,
    PageType.GeoObject,
  ],
  [
    `/ferien/reiseziele`,
    `/vacances/destinations`,
    `/geo-overview`,
    PageType.GeoOverview,
  ],
  [`/konto`, `/compte`, `/my-account`, PageType.MyaccountOverview],
  [
    `/konto/meine-reisen`,
    `/compte/mes-voyages`,
    `/my-account-booking`,
    PageType.MyaccountMybookings,
  ],
  [
    `/konto/meine-reisen/buchung/:id`,
    `/compte/mes-voyages/reservation/:id`,
    `/my-account-booking-details`,
    PageType.MyaccountBookingDetails,
  ],
  [
    `/konto/login-bestaetigen`,
    `/compte/login-confirmer`,
    `/my-account-confirmation`,
    PageType.MyaccountConfirmation,
  ],
  [
    `/konto/meine-suchen`,
    `/compte/mon-historique`,
    `/my-account-history`,
    PageType.MyaccountMyhistory,
  ],
  [
    `/konto/login`,
    `/compte/login`,
    `/my-account-login`,
    PageType.MyaccountLogin,
  ],
  [
    `/konto/mein-konto`,
    `/compte/mon-compte`,
    `/my-account-profile`,
    PageType.MyaccountMyprofile,
  ],
  [
    `/konto/registrieren`,
    `/compte/enregistrer`,
    `/my-account-registration`,
    PageType.MyaccountRegistration,
  ],
  [
    `/konto/passwort-zuruecksetzen`,
    `/compte/reinitialiser-mot-de-passe`,
    `/my-account-reset`,
    PageType.MyaccountResetPassword,
  ],
  [
    `/newsletter/anmeldung`,
    `/newsletter/abonnement`,
    `/newsletter-subscribe`,
    PageType.NewsletterSubscription,
  ],
  [
    `/newsletter/anmeldung-bestaetigen`,
    `/newsletter/abonnement-confirmer`,
    `/newsletter-subscribe-confirm`,
    PageType.NewsletterConfirmation,
  ],
  [
    `/newsletter/anmeldung-erfolgreich`,
    `/newsletter/abonnement-succes`,
    `/newsletter-subscribe-success`,
    PageType.NewsletterFinalization,
  ],
  [
    `/newsletter/abmeldung`,
    `/newsletter/desabonnement`,
    `/newsletter-unsubscribe`,
    PageType.NewsletterUnsubscription,
  ],
  [
    `/newsletter/abmeldung-erfolgreich`,
    `/newsletter/desabonnement-succes`,
    `/newsletter-unsubscribe-success'`,
    PageType.NewsletterUnsubscriptionFinalization,
  ],
  [
    `/ferien/:destination/:params/:id([hos]-\\d+)`,
    `/vacances/:destination/:params/:id([hos]-\\d+)`,
    `/pdp-description`,
    PageType.PdpDescription,
  ],
  [
    `/ferien/:destination/:params/:id([hos]-\\d+)/region-klima`,
    `/vacances/:destination/:params/:id([hos]-\\d+)/region-climat`,
    `/pdp-destination`,
    PageType.PdpRegion,
  ],
  [
    `/ferien/:destination/:params/:id([hos]-\\d+)/preise`,
    `/vacances/:destination/:params/:id([hos]-\\d+)/prix`,
    `/pdp-orl`,
    PageType.Orl,
  ],
  [
    `/ferien/:destination/:params/:id([hos]-\\d+)/bewertungen`,
    `/vacances/:destination/:params/:id([hos]-\\d+)/evaluations`,
    `/pdp-reviews`,
    PageType.PdpReviews,
  ],
  [`/ferien/suchen`, `/vacances/chercher`, `/search`, PageType.Srl],
  [
    `/ferien/:params/:id(t-\\d+)`,
    `/vacances/:params/:id(t-\\d+)`,
    `/theme`,
    PageType.ThemeObject,
  ],
  [
    `/ferien/reiseideen`,
    `/vacances/idees-de-voyage`,
    `/theme-overview`,
    PageType.ThemeOverview,
  ],
  [`/merkliste`, `/liste-de-favoris`, `/wishlist-list`, PageType.Wishlist],
  [
    `/merkliste/teilen/:id`,
    `/liste-de-favoris/partager/:id`,
    `/wishlist-share`,
    PageType.Wishlist,
  ],
  [
    `/merkliste/listenansicht`,
    `/liste-de-favoris/vue-liste`,
    `/wishlist-list`,
    PageType.WishlistList,
  ],
  [
    `/merkliste/vergleichen`,
    `/liste-de-favoris/comparer`,
    `/wishlist-compare`,
    PageType.WishlistCompare,
  ],
  [`/:path*`, `/:path*`, `/[...slug]`, PageType.Static, false, true],
].forEach(([de, fr, page, pageType, prefix, fallback]) => {
  const entries = Object.entries({ de, fr });

  entries.forEach(([locale, match]) => {
    routes.push(
      registerRouteConfig({
        page,
        pageType,
        match,
        locale,
        prefix,
        fallback,
      })
    );
  });
});
