import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { expandChat } from '@hotelplan/components.common.chat';
import { FooterHeading } from '@hotelplan/components.common.footer';
import { Icon } from '@hotelplan/components.common.icon';
import { Link } from '@hotelplan/components.common.link';
import { RouterLink } from '@hotelplan/components.common.router-link';
import {
  AuthChannelType,
  DeviceType,
  PageLanguage,
  PageType,
} from '@hotelplan/graphql.types';
import { useRequestContext } from '@hotelplan/libs.context.req-ctx';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { ClickEventType, trackClicks } from '@hotelplan/libs.tracking';
import { ExternalLink } from '@hotelplan/platform-graphql-api';
import { sx2CssThemeFn, SxStyleProp } from '@hotelplan/util.theme.sxc';
import AgencyAppoinment from 'components/domain/agency-appoinment/agency-appoinment';
import Chat from 'components/domain/chat-container/ChatContainer';
import { useGetPersonalProfileQuery } from 'graphql/account/GetPersonalProfile.generated';
import { useGetAgencyContactQuery } from 'graphql/agency/GetAgencyContact.generated';

const linkCss: SxStyleProp = {
  display: 'flex',
  marginBottom: [2, 3],
  fontSize: [2, 3],
  lineHeight: ['24px', '30px'],
  '.icon': {
    margin: 'auto',
  },
};

const linkIconCss: SxStyleProp = {
  display: 'flex',
  marginRight: 2,
};

const linkTextCss: SxStyleProp = {
  flex: 1,
  borderLeft: 'none',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: ['column', 'row'],
  justifyContent: ['center', 'flex-start'],
  alignItems: 'start',
  px: [3, 0],
};

interface IFooterContactsProps {
  emailAddress: string;
  emailTitle: string;
  mainTitle: string;
  openingHours: string;
  phoneNumber: string;
  phoneTitle: string;
  appointmentLink?: ExternalLink;
}

const FooterContactsWrapper = styled.div(
  ({ theme: { space, colors, media } }) => ({
    marginTop: space[3],
    padding: space[4],
    backgroundColor: colors.background,
    flexGrow: 1,
    [media.tablet]: {
      marginTop: 0,
      flexBasis: '50%',
    },
    a: {
      textDecoration: 'underline',
      textDecorationColor: colors.linkUnderlineColor,
      textUnderlineOffset: '6px',
      color: colors.defaultText,
      transition: 'none',
      transform: 'translate(0)',
      '&:hover,&:focus': {
        color: colors.defaultText,
        textDecorationColor: colors.defaultText,
        transform: 'scale(1)',
      },
    },
  })
);

const FooterContactsTitle = styled(FooterHeading)(
  sx2CssThemeFn({
    display: 'block',
    textAlign: 'start',
    marginBottom: 4,
  })
);

const PhoneLink = styled(Link)(
  sx2CssThemeFn(linkCss, {
    marginBottom: 3,
    lineHeight: ['24px', '30px'],
  })
);

const PhoneIcon = styled.span.attrs({
  children: <Icon name="phone" size="lg" />,
})(sx2CssThemeFn(linkIconCss));

const PhoneContent = styled.div(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn(linkTextCss, {
    paddingLeft: 0,
    '.-number': FONT_SIZE.LARGE,
  })
);

const EmailLink = styled(Link)(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn(linkCss, {
    marginBottom: 3,
    '.-email': FONT_SIZE.LARGE,
  })
);

const EmailContent = styled.div(
  sx2CssThemeFn(linkTextCss, {
    paddingLeft: 0,
  })
);

const EmailIcon = styled.span.attrs({
  children: <Icon name="mail" size="lg" />,
})(sx2CssThemeFn(linkIconCss));

const ChatLink = styled(Link)(
  sx2CssThemeFn(linkCss, {
    marginBottom: 4,
    '&:hover': {
      cursor: 'pointer',
    },
  })
);

const ChatContent = styled.div(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn(linkTextCss, {
    ...FONT_SIZE.LARGE,
    paddingLeft: 0,
  })
);

const ChatIcon = styled.span.attrs({
  children: <Icon name="chat" size="lg" />,
})(sx2CssThemeFn(linkIconCss));

const ContactPageContainer = styled.div(
  sx2CssThemeFn({
    a: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 3,
      fontSize: [1, '18px'],
      lineHeight: ['24px', '28px'],
    },
  })
);

const ContactPageLink = styled(Link).attrs({
  'data-id': `contact-page-link`,
})(({ theme: { space, colors, fontSizes, media } }) => ({
  '.link-text': {
    fontSize: fontSizes[2],
    lineHeight: '24px',
    paddingLeft: 0,
    [media.tablet]: {
      fontSize: '18px',
      lineHeight: '28px',
    },
  },
  '.icon': {
    fill: colors.black,
    marginRight: space[2],
  },
}));

const WorkingTime = styled.p(
  sx2CssThemeFn({
    textAlign: 'start',
    color: 'darkGray',
    fontSize: [1, 2],
    lineHeight: ['20px', '24px'],
  })
);

const phoneClickHandler = () =>
  trackClicks({
    type: ClickEventType.CALL,
    payload: { source: 'footer' },
  });

const appointmentClickHandler = () =>
  trackClicks({
    type: ClickEventType.APPOINTMENT,
    payload: { source: 'footer' },
  });

const emailClickHandler = () =>
  trackClicks({
    type: ClickEventType.EMAIL,
    payload: { source: 'footer' },
  });

const contactClickHandler = () =>
  trackClicks({
    type: ClickEventType.CONTACT,
    payload: { source: 'footer' },
  });

const chatClickHandler = () =>
  trackClicks({
    type: ClickEventType.CHAT,
    payload: { source: 'footer' },
  });

const DEFAULT_AGENCY_ID = 'CH9131';

const FooterContacts: React.FC<IFooterContactsProps> = ({
  emailAddress,
  emailTitle,
  openingHours,
  phoneNumber,
  appointmentLink,
}) => {
  const [t, { language }] = useTranslation(['common', 'agency']);
  const context = useRequestContext<DeviceType, PageLanguage>();
  const { channelType } = useAuthentication();
  const useAccountAgency = channelType === AuthChannelType.B2C;

  const { data: account } = useGetPersonalProfileQuery({
    variables: { context },
    skip: !useAccountAgency,
  });

  const { agency } = useRequestContext();
  const isDefaultAgency = !agency || agency === DEFAULT_AGENCY_ID;

  const { data: agencyContactData } = useGetAgencyContactQuery({
    variables: { id: agency || account?.account?.personalData?.agency || '' },
  });

  const { query } = useRouter();

  const [isModalShowed, openModal, closeModal] = useToggleState(
    typeof query.appointment !== 'undefined'
  );

  const routerProps = useMemo(() => {
    const route = getRouteByPageTypeLocale(PageType.Contact, language);
    return getTargetPageData({
      targetPageType: route.pageType,
      uri: route.builder({}),
    });
  }, [language]);

  return (
    <FooterContactsWrapper data-id="footer-contact">
      <FooterContactsTitle data-id="title">
        {agencyContactData?.agencyContact.phone.title}
      </FooterContactsTitle>
      <PhoneLink
        href={`tel:${phoneNumber}`}
        data-id="phone-link"
        onClick={phoneClickHandler}
      >
        <PhoneIcon />
        <PhoneContent data-id="phone-content">
          <span className="-number" data-id="phone-number">
            {phoneNumber}
          </span>
        </PhoneContent>
      </PhoneLink>

      {isDefaultAgency ? (
        <ContactPageContainer>
          <RouterLink {...routerProps} passHref>
            <ContactPageLink onClick={contactClickHandler}>
              <Icon name="mail" size="lg" />
              <span className="link-text">
                {t('common:footer_contact_box.contactPage')}
              </span>
            </ContactPageLink>
          </RouterLink>
        </ContactPageContainer>
      ) : (
        <EmailLink
          href={emailAddress}
          data-id="email-link"
          onClick={emailClickHandler}
        >
          <EmailIcon />
          <EmailContent>
            <span className="-email" data-id="email-title">
              {emailTitle}
            </span>
          </EmailContent>
        </EmailLink>
      )}

      {isDefaultAgency ? (
        <ChatLink
          href={emailAddress}
          data-id="chat-link"
          onClick={e => {
            e.preventDefault();
            expandChat();
            chatClickHandler();
          }}
        >
          <ChatIcon />
          <ChatContent>
            <span data-id="chat-title">
              {t('common:footer_contact_box.chat')}
            </span>
          </ChatContent>
          <Chat />
        </ChatLink>
      ) : (
        <ChatLink
          data-id="agency-appointment-link"
          onClick={() => {
            openModal();
            appointmentClickHandler();
          }}
        >
          <ChatIcon />
          <ChatContent>
            <span data-id="agency-appointment-title">
              {t('common:openingHours.signup')}
            </span>
          </ChatContent>
          {isModalShowed && appointmentLink?.url && (
            <AgencyAppoinment closeModal={closeModal} link={appointmentLink} />
          )}
        </ChatLink>
      )}

      <WorkingTime data-id="opening-hours">{openingHours}</WorkingTime>
    </FooterContactsWrapper>
  );
};

export default FooterContacts;
