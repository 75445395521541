import { useMetaModulesContext } from '@hotelplan/components.common.meta-modules';
import { useGetCookieDisclaimerQuery } from 'graphql/layout/GetCookieDisclaimer.generated';

export function useGetCookieDisclaimer() {
  const { hideCookieConsent } = useMetaModulesContext();

  const { data: cookieDisclaimerData } = useGetCookieDisclaimerQuery({
    skip: hideCookieConsent,
  });

  return {
    cookieDisclaimerText: cookieDisclaimerData?.cookieDisclaimer.text,
  };
}
