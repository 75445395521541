import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { ResizedImageContentFragmentDoc } from './ResizedImageContent.generated';
export type ResizedImageWithMetaFragment = {
  __typename?: 'Image';
  alt: string;
  desaturated?: boolean | null;
  resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
};

export const ResizedImageWithMetaFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResizedImageWithMeta' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resized' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageContent' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'desaturated' } },
        ],
      },
    },
    ...ResizedImageContentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
