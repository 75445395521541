import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const SkeletonBaseBlock = styled.div<{
  height?: string | [string, string];
  width?: string | [string, string];
  borderRadius?: string | [string, string];
}>(({ height = ['14px', '16px'], width, borderRadius }) =>
  sx2CssThemeFn({
    position: 'relative',
    height,
    width,
    borderRadius,
    background:
      'linear-gradient(90deg, rgba(245, 246, 247, 1) 30%, rgba(244, 245, 246, 1) 35%, rgba(238, 238, 238, 1) 50%, rgba(245, 246, 247, 1) 70%)',
    backgroundSize: '400% 300%',
    animation: 'loadingAnimation 2s infinite',
    '@keyframes loadingAnimation': {
      '0%': { backgroundPosition: '100% 50%' },
      '50%, 100%': { backgroundPosition: '0% 50%' },
    },
  })
);
