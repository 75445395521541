import type { TFunction } from 'next-i18next';
import {
  AgencyAppointmentInputInput,
  AgencyAppointmentCallbackTimeDayType,
  AgencyAppointmentCallbackTimeInput,
  OpeningHours,
} from '@hotelplan/graphql.types';
import { mapOpeningHoursToSchedules } from '@hotelplan/libs.schedule';
import type { IAgencyAppointmentFormState } from './Agency.types';

export function mapDayTimeInputToString(
  option: AgencyAppointmentCallbackTimeInput
): string {
  const { dayType, to, from } = option;
  return `${dayType}-${from}-${to}`;
}

export function formatDayTimeInput(
  option: AgencyAppointmentCallbackTimeInput
): string {
  const { to, from } = option;
  return `${from}-${to}`;
}

export function mapDayTimeStringToInput(
  timeString: string
): AgencyAppointmentCallbackTimeInput {
  const [dayType, from, to] = timeString.split('-');

  return {
    dayType: dayType as AgencyAppointmentCallbackTimeDayType,
    from,
    to,
  };
}

export function mapAppointmentFormStateToInput(
  agencyId: string,
  formState: IAgencyAppointmentFormState
): AgencyAppointmentInputInput {
  return {
    ...formState,
    agencyId,
    callbackTime: formState.callbackTime.map(mapDayTimeStringToInput),
  };
}

export function mapOpeningHoursToStringSchedule(
  t: TFunction,
  openingHours: OpeningHours[]
) {
  return mapOpeningHoursToSchedules(t, openingHours).join(' | ');
}
