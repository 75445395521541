import React from 'react';
import styled from 'styled-components';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Container } from 'components/domain/container';
import { SkeletonBaseBlock } from 'components/domain/srl-ui/SRLui.styles';

const ReasonsOfConfidenceSkeletonWrapper = styled.div(
  sx2CssThemeFn({
    backgroundColor: 'lightGreySixth',
  })
);

const ReasonsOfConfidenceSkeletonInner = styled.div(
  sx2CssThemeFn({
    display: ['flex', `grid`],
    gridTemplateColumns: '1fr 1fr',
    flexDirection: ['column', 'row'],
    gap: ['16px', '24px'],
    pt: '32px',
    px: ['16px', 0],
    pb: ['16px', '32px'],
  })
);

const ReasonsOfConfidenceItemSkeleton = styled.div<{ mt?: [string, string] }>(
  ({ mt }) =>
    sx2CssThemeFn({
      backgroundColor: 'white',
      width: '100%',
      p: '24px',
      mt,
    })
);

const SkeletonCircle = () => (
  <SkeletonBaseBlock
    width="24px"
    height="24px"
    borderRadius={'50%'}
    style={{ marginRight: '10px', flexShrink: 0 }}
  />
);

const reasonsOfConfidenceItems = [
  ['205px', '410px'],
  ['220px', '440px'],
  ['190px', '390px'],
  ['210px', '420px'],
  ['170px', '340px'],
  ['245px', '490px'],
];

const contactsItems = ['210px', '160px', '190px'];

const ReasonsOfConfidenceSkeleton = () => (
  <ReasonsOfConfidenceSkeletonWrapper>
    <Container>
      <ReasonsOfConfidenceSkeletonInner>
        <ReasonsOfConfidenceItemSkeleton>
          <SkeletonBaseBlock
            width="206px"
            height={['28px', '33px']}
            borderRadius={'4px'}
            style={{ marginBottom: '24px' }}
          />
          {reasonsOfConfidenceItems.map((width: [string, string], i) => (
            <div
              key={i}
              style={{
                marginBottom:
                  reasonsOfConfidenceItems.length - 1 !== i && '16px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SkeletonCircle />
              <SkeletonBaseBlock
                width={width}
                height={['24px', '27px']}
                borderRadius={'4px'}
              />
            </div>
          ))}
        </ReasonsOfConfidenceItemSkeleton>
        <div>
          <ReasonsOfConfidenceItemSkeleton>
            <SkeletonBaseBlock
              width="266px"
              height={['28px', '33px']}
              borderRadius={'4px'}
              style={{ marginBottom: '24px' }}
            />
            {contactsItems.map((width: string, i) => (
              <div
                key={i}
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SkeletonCircle />
                <SkeletonBaseBlock
                  width={width}
                  height={['24px', '27px']}
                  borderRadius={'4px'}
                />
              </div>
            ))}
            <SkeletonBaseBlock
              width={['280px', '406px']}
              height={['20px', '24px']}
              borderRadius={'4px'}
              style={{ marginTop: '24px' }}
            />
          </ReasonsOfConfidenceItemSkeleton>
          <ReasonsOfConfidenceItemSkeleton mt={['16px', '24px']}>
            <SkeletonBaseBlock
              width={['280px', '366px']}
              height={['28px', '33px']}
              borderRadius={'4px'}
              style={{ marginBottom: '24px' }}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SkeletonCircle />
              <SkeletonBaseBlock
                width={['230px', '420px']}
                height={['24px', '27px']}
                borderRadius={'4px'}
              />
            </div>
          </ReasonsOfConfidenceItemSkeleton>
        </div>
      </ReasonsOfConfidenceSkeletonInner>
    </Container>
  </ReasonsOfConfidenceSkeletonWrapper>
);

const FooterContentSkeletonInner = styled.div(
  sx2CssThemeFn({
    py: ['16px', '32px'],
    px: ['16px', 0],
    display: 'flex',
    flexDirection: ['column', 'row'],
    alignItems: 'center',
    justifyContent: 'space-between',
  })
);

const FooterContentSkeletonWrapper = styled.div(
  sx2CssThemeFn({
    borderBottom: '1px solid',
    borderColor: 'lightGreySixth',
  })
);

const SocialsWrapper = styled.div(
  sx2CssThemeFn({
    display: ['block', 'flex'],
    width: '100%',
    alignItems: 'center',
    gap: '32px',
    '.socials-title': {
      mb: ['10px', 0],
    },
  })
);

const SocialsList = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    gap: ['8px', '24px'],
    justifyContent: 'flex-start',
  })
);

const PaymentsList = styled.div({ display: 'flex', gap: '10px' });

const PaymentsWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    width: ['100%', 'auto'],
  })
);
const PaymentsInner = styled.div(
  sx2CssThemeFn({
    display: ['block', 'flex'],
    width: '100%',
    alignItems: 'center',
    gap: '10px',
    height: [null, '82px'],
    paddingLeft: [0, '32px'],
    mt: ['32px', 0],
    '.payments-title': {
      mb: ['10px', 0],
    },
  })
);

const Separator = styled(SkeletonBaseBlock)(
  sx2CssThemeFn({ display: ['none', 'block'] })
);

const FooterContentSkeleton = () => (
  <FooterContentSkeletonWrapper>
    <Container>
      <FooterContentSkeletonInner>
        <SocialsWrapper>
          <SkeletonBaseBlock
            width={['230px', '120px']}
            height={['28px', '24px']}
            borderRadius={'4px'}
            className="socials-title"
          />
          <SocialsList>
            {Array.from({ length: 5 }, (_, i) => (
              <SkeletonBaseBlock
                key={i}
                width={['60px', '35px']}
                height={['50px', '25px']}
                borderRadius={'4px'}
              />
            ))}
          </SocialsList>
        </SocialsWrapper>
        <PaymentsWrapper>
          <Separator width="1px" height="82px" />
          <PaymentsInner>
            <SkeletonBaseBlock
              width={['230px', '145px']}
              height={['28px', '24px']}
              borderRadius={'4px'}
              className="payments-title"
            />
            <PaymentsList>
              {Array.from({ length: 6 }, (_, i) => (
                <SkeletonBaseBlock
                  key={i}
                  width={['50px', '52px']}
                  height={['50px', '36px']}
                  borderRadius={'4px'}
                />
              ))}
            </PaymentsList>
          </PaymentsInner>
        </PaymentsWrapper>
      </FooterContentSkeletonInner>
    </Container>
  </FooterContentSkeletonWrapper>
);

const FooterMenuBottomWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    py: '32px',
    justifyContent: 'space-between',
  })
);

const LinksList = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    width: ['100%', 'initial'],
    gap: '24px',
    px: ['16px', 0],
  })
);

const links = ['59px', '83px', '85px', '29px', '73px', '48px'];

const FooterMenuBottomSkeletonDesktop = () => (
  <Container>
    <FooterMenuBottomWrapper>
      <SkeletonBaseBlock width={'173px'} height={'60px'} />
      <SkeletonBaseBlock width={'250px'} height={'18px'} />
      <LinksList>
        {links.map((width: string, i) => (
          <SkeletonBaseBlock
            key={i}
            width={width}
            height={'20px'}
            borderRadius={'4px'}
          />
        ))}
      </LinksList>
      <SkeletonBaseBlock width={'115px'} height={'34px'} />
    </FooterMenuBottomWrapper>
  </Container>
);

const FooterMenuBottomWrapperMobile = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '32px',
    py: '16px',
  })
);

const LinksBlock = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    justifyContent: 'space-between',
    width: '166px',
  })
);

const FooterMenuBottomSkeletonMobile = () => (
  <Container>
    <FooterMenuBottomWrapperMobile>
      <LinksList>
        <LinksBlock>
          {links.slice(0, 3).map((width: string, i) => {
            return (
              <SkeletonBaseBlock
                key={i}
                width={width}
                height={'20px'}
                borderRadius={'4px'}
              />
            );
          })}
        </LinksBlock>
        <LinksBlock>
          {links.slice(3).map((width: string, i) => {
            return (
              <SkeletonBaseBlock
                key={i}
                width={width}
                height={'20px'}
                borderRadius={'4px'}
              />
            );
          })}
        </LinksBlock>
      </LinksList>
      <SkeletonBaseBlock width={'115px'} height={'34px'} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <SkeletonBaseBlock width={'173px'} height={'60px'} />
        <SkeletonBaseBlock width={'250px'} height={'18px'} />
      </div>
    </FooterMenuBottomWrapperMobile>
  </Container>
);

const FooterSkeleton: React.FC = () => {
  const { mobile } = useDeviceType();

  return (
    <>
      <ReasonsOfConfidenceSkeleton />
      <FooterContentSkeleton />
      {mobile ? (
        <FooterMenuBottomSkeletonMobile />
      ) : (
        <FooterMenuBottomSkeletonDesktop />
      )}
    </>
  );
};

export default FooterSkeleton;
