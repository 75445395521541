import Cookies from 'js-cookie';
import { Trans, useTranslation } from 'next-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import {
  BoosterList,
  BoosterWrap,
  BoosterCloseButton,
} from '@hotelplan/components.common.boosters';
import { expandChat } from '@hotelplan/components.common.chat';
import RouterLink from '@hotelplan/components.common.router-link';
import { PageType } from '@hotelplan/graphql.types';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import {
  getTargetPageData,
  useCurrentRoute,
} from '@hotelplan/libs.router-link-utils';
import { ClickEventType, trackClicks } from '@hotelplan/libs.tracking';
import Chat from 'components/domain/chat-container/ChatContainer';
import { useHelpOverlayToggleStateContext } from 'components/domain/site-tool-buttons';
import { useInactivityTimer } from 'components/domain/timer/useInactivityTimer';

const CUSTOMER_SUPPORT_BOOSTER_COOKIE_NAME = 'showCustomerSupportBooster';
const CUSTOMER_SUPPORT_BOOSTER_TIMEOUT = 1000 * 60 * 2;

const AgencyLink: React.FC<{
  children?: React.ReactNode;
  onClick(): void;
}> = props => {
  return (
    <RouterLink {...props} passHref={true}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a target={'_blank'} onClick={props.onClick}>
        {props.children}
      </a>
    </RouterLink>
  );
};

const CustomerSupportBoosterBanner: React.FC = () => {
  const [t, { language }] = useTranslation('common');
  const i18nKey = 'common:customer_support.booster.text';
  const [showBooster, setShowBooster] = useState(false);
  const { enable: openHelpOverlay } = useHelpOverlayToggleStateContext();
  const { route: currentRoute } = useCurrentRoute<{}, PageType>();

  const agencyRouteProps = useMemo(() => {
    const route = getRouteByPageTypeLocale(PageType.AgencyOverview, language);

    return route
      ? getTargetPageData({
          targetPageType: route.pageType,
          uri: route.builder({}),
        })
      : null;
  }, [language]);

  function hideBooster() {
    setShowBooster(false);
    Cookies.set(CUSTOMER_SUPPORT_BOOSTER_COOKIE_NAME, 'false');
  }

  useEffect(() => {
    if (showBooster && currentRoute?.page) {
      hideBooster();
    }
  }, [currentRoute?.page]);

  useInactivityTimer({
    timeout: CUSTOMER_SUPPORT_BOOSTER_TIMEOUT,
    onIdle: () => {
      if (Cookies.get(CUSTOMER_SUPPORT_BOOSTER_COOKIE_NAME) !== 'false') {
        setShowBooster(true);
        Cookies.set(CUSTOMER_SUPPORT_BOOSTER_COOKIE_NAME, 'false');
      }
    },
  });

  if (!showBooster) {
    return null;
  }

  return (
    <BoosterList>
      <BoosterWrap
        key={'customer-support-booster'}
        data-id={`booster-customer-support`}
        className="booster-wrapper"
      >
        <div className="booster-text">
          <Trans
            i18nKey={i18nKey}
            components={[
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href={'#'}
                key={'chat'}
                onClick={() => {
                  expandChat();
                  trackClicks({
                    type: ClickEventType.CHAT,
                    payload: { source: 'booster' },
                  });
                  hideBooster();
                }}
              />,
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href={'#'}
                key={'phone'}
                onClick={() => {
                  hideBooster();
                  openHelpOverlay();
                }}
              />,
              <AgencyLink
                {...agencyRouteProps}
                key={'agency'}
                onClick={hideBooster}
              />,
            ]}
          />
        </div>
        <Chat />
        <BoosterCloseButton onClick={hideBooster}>
          {t('common:close')}
        </BoosterCloseButton>
      </BoosterWrap>
    </BoosterList>
  );
};

export default CustomerSupportBoosterBanner;
