import dynamic from 'next/dynamic';
import Head from 'next/head';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LastSeenHotels } from '@hotelplan/components.common.last-seen-hotels';
import { LazyHydrate } from '@hotelplan/components.common.lazy-render';
import { SharingUrlProvider } from '@hotelplan/components.common.share-form';
import {
  DeviceType,
  getDeviceTypeByScreenWidth,
  useDeviceType,
} from '@hotelplan/libs.context.device-type';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { trackLastSeenHotelClick } from '@hotelplan/libs.tracking';
import { LastSeenItem } from '@hotelplan/platform-graphql-api';
import { Container } from 'components/domain/container';
import CustomerSupportBoosterBanner from 'components/domain/customer-support-element/CustomerSupportBoosterBanner';
import Footer from 'components/domain/footer/Footer';
import SkipLinksComponent from 'components/domain/keyboard-navigation/SkipLinksComponent';
import Fonts from 'components/domain/layout/Fonts';
import BoostersBanner from 'components/domain/meta-modules/BoostersBanner';
import CookiesConsentBanner from 'components/domain/meta-modules/CookiesConsentBanner';
import InfoNotificationBanner from 'components/domain/notifications/InfoNotificationBanner';
import { HelpOverlayToggleStateContextProvider } from 'components/domain/site-tool-buttons';
import { ViewedProductContextProvider } from 'components/domain/viewed-product-tracking/ViewedProductContext';
import { FeatureList } from 'config/pageConfig.constants';

const MetaModules = dynamic(
  () => import('components/domain/meta-modules/MetaModules'),
  {
    ssr: false,
  }
);

const HeaderDesktop = dynamic<{}>(
  () => import('components/domain/header/HeaderDesktop')
);

const HeaderMobile = dynamic<{}>(
  () => import('components/domain/header/HeaderMobile')
);

const ContentWrapper = styled.div.attrs({ 'data-id': `content-wrapper` })({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

const ContentChildrenContainer = styled.div.attrs({
  'data-id': `content-children`,
})({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

const Layout: React.FC<{
  children?: React.ReactNode;
  disableFooter?: boolean;
  lastSeenHotels?: LastSeenItem[];
}> = props => {
  const { children, disableFooter, lastSeenHotels } = props;
  const [t] = useTranslation();
  const { isEnabled } = useFeatureToggle();
  const { serverType, mobile } = useDeviceType();
  const [isTablet] = useState(
    () =>
      (typeof window !== 'undefined'
        ? getDeviceTypeByScreenWidth(window.screen.availWidth)
        : serverType) === DeviceType.Tablet
  );

  const isNotDesktop = serverType !== DeviceType.Desktop;
  const currentScale = isTablet ? '0.8' : '1';

  const header = useMemo(() => {
    const isMobileView = typeof window === 'undefined' ? isNotDesktop : mobile;

    return isMobileView ? <HeaderMobile /> : <HeaderDesktop />;
  }, [serverType, mobile]);

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://tracking.bd4travel.com/" />
        <Fonts />
        <meta
          key="vp"
          name="viewport"
          content={`width=device-width, initial-scale=${currentScale}, viewport-fit=cover`}
        />
      </Head>
      <ViewedProductContextProvider>
        <SharingUrlProvider>
          <HelpOverlayToggleStateContextProvider defaultValue={false}>
            <ContentWrapper>
              <SkipLinksComponent />
              <InfoNotificationBanner />
              <LazyHydrate whenIdle={true} noWrapper={true}>
                {header}
              </LazyHydrate>
              <ContentChildrenContainer id="maincontent">
                {children}
              </ContentChildrenContainer>
              {lastSeenHotels?.length ? (
                <Container>
                  <LastSeenHotels
                    lastSeenHotels={lastSeenHotels}
                    title={t('common:lastSeenHotels.title')}
                    openInNewPage
                    trackClick={trackLastSeenHotelClick}
                  />
                </Container>
              ) : null}
              {!disableFooter && <Footer />}
            </ContentWrapper>
            <CookiesConsentBanner />
            <BoostersBanner />
            <LazyHydrate whenIdle={true} noWrapper={true}>
              <div>
                <MetaModules />
                {isEnabled(FeatureList.CUSTOMER_SUPPORT_BOOSTER) && (
                  <CustomerSupportBoosterBanner />
                )}
              </div>
            </LazyHydrate>
          </HelpOverlayToggleStateContextProvider>
        </SharingUrlProvider>
      </ViewedProductContextProvider>
    </>
  );
};

export default Layout;
