import { useTranslation } from 'next-i18next';
import { useAuthentication } from '@hotelplan/components.common.auth';
import type { IFooterContactsProps } from '@hotelplan/components.common.footer';
import { getNavigationLinks } from '@hotelplan/components.common.menu';
import {
  AuthChannelType,
  DeviceType,
  IconMenuItem,
  Navigable,
  PageLanguage,
} from '@hotelplan/graphql.types';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { useRequestContext } from '@hotelplan/libs.context.req-ctx';
import { mapOpeningHoursToStringSchedule } from 'components/domain/agency/Agency.mappers';
import { useGetPersonalProfileQuery } from 'graphql/account/GetPersonalProfile.generated';
import { useGetAgencyContactQuery } from 'graphql/agency/GetAgencyContact.generated';
import { useGetFooterQuery } from 'graphql/layout/GetFooter.generated';
import type { IconMenuEntryFragment } from 'graphql/layout/IconMenuEntry.generated';

export const useGetFooterData = () => {
  const pageType = usePageType();

  return useGetFooterQuery({
    notifyOnNetworkStatusChange: true,
    skip: !pageType,
  });
};

const usePageFooter = () => {
  const [t] = useTranslation(['common', 'agency']);
  const { data: footerData, loading } = useGetFooterData();
  const context = useRequestContext<DeviceType, PageLanguage>();
  const { agency } = context;
  const { channelType } = useAuthentication();
  const useAccountAgency = channelType === AuthChannelType.B2C;

  const { data: account, loading: accountLoading } = useGetPersonalProfileQuery(
    {
      variables: { context },
      skip: !useAccountAgency,
    }
  );

  const navMenu = footerData?.pageFooter.navigationMenu || [];
  const navLinks = getNavigationLinks(navMenu as Navigable[]);

  const { data: agencyContactData } = useGetAgencyContactQuery({
    variables: { id: agency || account?.account?.personalData?.agency || '' },
    skip: useAccountAgency && accountLoading,
  });

  return {
    loading,
    navigationMenu: navLinks,
    socialMedia: (footerData?.pageFooter.socialMedia ||
      []) as IconMenuEntryFragment[],
    paymentMethods: (footerData?.pageFooter.paymentMethods ||
      []) as IconMenuEntryFragment[],
    reasonsOfConfidence: {
      title: footerData?.pageFooter.reasonsOfConfidence?.title || '',
      text: footerData?.pageFooter.reasonsOfConfidence?.text || '',
    },
    contacts: {
      emailAddress: agencyContactData?.agencyContact.emailLink.url,
      emailTitle:
        agencyContactData?.agencyContact.emailLink.caption ||
        agencyContactData?.agencyContact.emailLink.url,
      mainTitle: agencyContactData?.agencyContact.title,
      openingHours: mapOpeningHoursToStringSchedule(
        t,
        agencyContactData?.agencyContact.openingHours || []
      ),
      phoneNumber: agencyContactData?.agencyContact.phone.link.caption,
      phoneTitle: agencyContactData?.agencyContact.phone.title,
      appointmentLink: agencyContactData?.agencyContact?.appointmentLink,
    } as IFooterContactsProps,
    brandLogos: (footerData?.pageFooter.brandLogos || []) as IconMenuItem[],
  };
};

export default usePageFooter;
