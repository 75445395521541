/* eslint-disable i18next/no-literal-string */
import mapValues from 'lodash/mapValues';
import type { CSSObject, DefaultTheme } from 'styled-components';
import { sx2CssTheme, SxStyleProp } from '@hotelplan/util.theme.sxc';
import {
  hotelplanStyle,
  E_BUTTON_TYPE,
} from '@hotelplan/style.hotelplan-style';

const { colors, buttons, transition, link } = hotelplanStyle;

const baseColors = {
  white: '#fff',
  black: '#000',
  gray: [
    '#fff',
    '#E1E1E1',
    '#EBEFF0',
    '#DDE5E7',
    '#EBEBEB',
    '#DDDDDD',
    '#F4F4F4',
  ],
};

const breakpoints = ['896px', '897px', '64em'];

const MEDIA_MOBILE = `@media screen and (max-width: 895px)`;
const MEDIA_TABLET = `@media screen and (min-width: ${breakpoints[0]})`;
const MEDIA_DESKTOP = `@media screen and (min-width: ${breakpoints[1]})`;

const media = {
  mobile: MEDIA_MOBILE,
  tablet: MEDIA_TABLET,
  desktop: MEDIA_DESKTOP,
};

const fontSizes = [12, 14, 16, 20, 24, 32, 40, 48, 52, 64].map(n => n + 'px');

export const spaces: number[] = [0, 4, 8, 16, 24, 32, 40, 64, 128, 256];

const space = spaces.map(n => n + 'px');

export enum E_FONT_FAMILY {
  DEFAULT = '"aktiv-grotesk", sans-serif',
  TITLE = '"PPAgrandir", sans-serif',
}

export enum E_FONT_WEIGHT {
  LIGHT = 300,
  REGULAR = 400,
  MEDIUM = 500,
  BOLD = 700,
}

export enum E_FONT_SIZE_KEYS {
  XL = 'XL',
  L = 'L',
  M = 'M',
  S = 'S',
  XS = 'XS',
  LARGE = 'LARGE',
  LARGE_BOLD = 'LARGE_BOLD',
  MEDIUM = 'MEDIUM',
  MEDIUM_BOLD = 'MEDIUM_BOLD',
  SMALL = 'SMALL',
  SMALL_BOLD = 'SMALL_BOLD',
  SMALLER = 'SMALLER',
  SMALLER_BOLD = 'SMALLER_BOLD',
  LINK_LARGE = 'LINK_LARGE',
  LINK_LARGE_BOLD = 'LINK_LARGE_BOLD',
  LINK_MEDIUM = 'LINK_MEDIUM',
  LINK_MEDIUM_BOLD = 'LINK_MEDIUM_BOLD',
  LINK_SMALL = 'LINK_SMALL',
  LINK_SMALL_BOLD = 'LINK_SMALL_BOLD',
  NAVIGATION = 'NAVIGATION',
  BUTTON = 'BUTTON',
  TABS = 'TABS',
}

const FONT_SIZE = {
  [E_FONT_SIZE_KEYS.XL]: {
    fontSize: ['34px', '48px'],
    lineHeight: ['46px', '66px'],
    fontWeight: E_FONT_WEIGHT.MEDIUM,
    fontFamily: E_FONT_FAMILY.TITLE,
  },
  [E_FONT_SIZE_KEYS.L]: {
    fontSize: ['26px', '34px'],
    lineHeight: ['38px', '48px'],
    fontWeight: E_FONT_WEIGHT.MEDIUM,
    fontFamily: E_FONT_FAMILY.TITLE,
  },
  [E_FONT_SIZE_KEYS.M]: {
    fontSize: ['21px', '27px'],
    lineHeight: ['32px', '42px'],
    fontWeight: E_FONT_WEIGHT.MEDIUM,
    fontFamily: E_FONT_FAMILY.TITLE,
  },
  [E_FONT_SIZE_KEYS.S]: {
    fontSize: ['19px', '21px'],
    lineHeight: ['28px', '34px'],
    fontWeight: E_FONT_WEIGHT.MEDIUM,
    fontFamily: E_FONT_FAMILY.TITLE,
  },
  [E_FONT_SIZE_KEYS.XS]: {
    fontSize: ['16px', '19px'],
    lineHeight: ['24px', '28px'],
    fontFamily: E_FONT_FAMILY.DEFAULT,
    fontWeight: E_FONT_WEIGHT.BOLD,
  },
  [E_FONT_SIZE_KEYS.LARGE_BOLD]: {
    fontSize: ['16px', '18px'],
    lineHeight: ['24px', '28px'],
    fontWeight: E_FONT_WEIGHT.BOLD,
  },
  [E_FONT_SIZE_KEYS.LARGE]: {
    fontSize: ['16px', '18px'],
    lineHeight: ['24px', '28px'],
    fontWeight: E_FONT_WEIGHT.REGULAR,
  },
  [E_FONT_SIZE_KEYS.MEDIUM]: {
    fontWeight: E_FONT_WEIGHT.REGULAR,
    fontFamily: E_FONT_FAMILY.DEFAULT,
    fontSize: '16px',
    lineHeight: '24px',
  },
  [E_FONT_SIZE_KEYS.MEDIUM_BOLD]: {
    fontWeight: E_FONT_WEIGHT.BOLD,
    fontFamily: E_FONT_FAMILY.DEFAULT,
    fontSize: '16px',
    lineHeight: '24px',
  },
  [E_FONT_SIZE_KEYS.SMALL]: {
    fontWeight: E_FONT_WEIGHT.REGULAR,
    fontSize: '14px',
    lineHeight: '20px',
  },
  [E_FONT_SIZE_KEYS.SMALL_BOLD]: {
    fontWeight: E_FONT_WEIGHT.BOLD,
    fontSize: '14px',
    lineHeight: '20px',
  },
  [E_FONT_SIZE_KEYS.SMALLER]: {
    fontWeight: E_FONT_WEIGHT.REGULAR,
    fontSize: '12px',
    lineHeight: '18px',
  },
  [E_FONT_SIZE_KEYS.SMALLER_BOLD]: {
    fontWeight: E_FONT_WEIGHT.BOLD,
    fontSize: '12px',
    lineHeight: '18px',
  },
  [E_FONT_SIZE_KEYS.LINK_LARGE]: {
    fontSize: ['16px', '18px'],
    lineHeight: ['24px', '28px'],
    fontWeight: E_FONT_WEIGHT.REGULAR,
    fontFamily: E_FONT_FAMILY.DEFAULT,
  },
  [E_FONT_SIZE_KEYS.LINK_LARGE_BOLD]: {
    fontSize: ['16px', '18px'],
    lineHeight: ['24px', '28px'],
    fontWeight: E_FONT_WEIGHT.BOLD,
  },
  [E_FONT_SIZE_KEYS.LINK_MEDIUM]: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: E_FONT_WEIGHT.REGULAR,
  },
  [E_FONT_SIZE_KEYS.LINK_MEDIUM_BOLD]: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: E_FONT_WEIGHT.BOLD,
  },
  [E_FONT_SIZE_KEYS.LINK_SMALL]: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: E_FONT_WEIGHT.REGULAR,
  },
  [E_FONT_SIZE_KEYS.LINK_SMALL_BOLD]: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: E_FONT_WEIGHT.BOLD,
  },
  [E_FONT_SIZE_KEYS.NAVIGATION]: {
    fontSize: '18px',
    fontWeight: E_FONT_WEIGHT.LIGHT,
    fontFamily: E_FONT_FAMILY.TITLE,
    lineHeight: '26px',
  },
  [E_FONT_SIZE_KEYS.BUTTON]: {
    fontSize: '18px',
    fontWeight: E_FONT_WEIGHT.REGULAR,
    lineHeight: '28px',
  },
  [E_FONT_SIZE_KEYS.TABS]: {
    fontSize: ['14px', '18px'],
    lineHeight: ['20px', '28px'],
    fontWeight: E_FONT_WEIGHT.REGULAR,
  },
};

const shadows: DefaultTheme['shadows'] = {
  topMedium: '0 -2px 10px 1px rgba(0,0,0,0.25)',
  bottomSmall: '0 4px 4px -4px rgba(0, 0, 0, .15)',
  small: '0 0 4px rgba(0, 0, 0, .15)',
  large: '0px 2px 4px rgba(0,0,0,.52)',
  subLinksList: '0px 16px 40px 0px rgba(0, 0, 0, 0.10)',
  footerCard: '0 2px 4px 1px rgba(0,0,0,0.1)',
  cardShadow: '0 3px 5px 1px rgba(0,0,0,0.06)',
  homeSearchFormWrap: '0px 2px 7px rgba(0,0,0,0.218859)',
  landDropdown: '0 4px 14px 0 rgba(0,0,0,0.17)',
  tooltip: '0 4px 8px 2px rgba(0,0,0,0.2)',
  blockShadow: '0 4px 7px 0 rgba(0,0,0,0.1)',
  notificationShadow: '0 2px 7px 2px rgba(0,0,0,0.32)',
};

const radii: DefaultTheme['radii'] = {
  default: '4px',
  circle: 99999,
  message: '5px',
  roundedTop: '4px 4px 0px 0px',
  roundedBottom: '0px 0px 4px 4px',
};

const icons: DefaultTheme['icons'] = {
  xs: {
    width: '13px',
    height: '13px',
  },
  sm: {
    width: '17px',
    height: '17px',
  },
  md: {
    width: '20px',
    height: '20px',
  },
  lg: {
    width: '24px',
    height: '24px',
  },
  xlg: {
    width: '28px',
    height: '28px',
  },
  xxlg: {
    width: '40px',
    height: '40px',
  },
};

const basicTheme = Object.freeze({
  breakpoints,
  media,
  colors,
  space,
  fontSizes,
  radii,
  transition,
  shadows,
  icons,
});

const sx = (sxp: SxStyleProp) => sx2CssTheme(basicTheme, sxp);

type MappedToCss<T extends object> = {
  [key in keyof T]: CSSObject;
};

const sxGroup = <T extends Record<string, SxStyleProp>>(
  groups: T
): MappedToCss<T> => mapValues(groups, sx);

const smallModalWrapper = sx({
  width: ['100%', 'auto'],
  '.modal-body': {
    backgroundColor: baseColors.white,
  },
});

const commonModalBody = sx({
  px: [4, '40px'],
  pt: [4, '30px'],
  pb: [4, '40px'],
});

const ratingTextStyles = ({ theme }) =>
  sx2CssTheme(theme, {
    hyphens: 'auto',
    flex: '0 12 auto',
    wordBreak: 'break-word',
    mr: 2,
  });

const theme: DefaultTheme = Object.freeze({
  ...basicTheme,
  FONT_SIZE: sxGroup(FONT_SIZE),
  skipLinks: sxGroup({
    skipLink: {
      clip: 'rect(0,0,0,0)',
      border: '0',
      display: 'block',
      height: '1px',
      margin: '-1px',
      overflow: 'hidden',
      padding: '1rem',
      position: 'absolute',
      transition: 'padding 0s',
      width: '1px',
      background: colors.primary,
      color: colors.white,
      fontSize: fontSizes[2],
      '&:focus': {
        height: 'auto',
        margin: '0.5rem',
        overflow: 'initial',
        position: 'relative',
        width: 'auto',
      },
      '&:hover': {
        color: colors.white,
      },
    },
    skipLinksWrapper: {
      position: 'fixed',
      zIndex: 1000,
    },
    skipLinksTitle: {
      display: 'none',
    },
  }),
  shareForm: sxGroup({
    detailsContainer: {
      display: ['flex', 'grid'],
      gridTemplateColumns: ['1fr', '320px 1fr'],
      flexDirection: ['column', 'row'],
      gap: [2, 3],
      pb: [2, 3],
      '.title': {
        ...FONT_SIZE.XS,
        mb: [3, 0],
      },
    },
    detailsImage: {
      gridRow: [null, 'span 2'],
      height: '180px',
      borderRadius: 'default',
      overflow: 'hidden',
    },
    detailsSocials: {
      alignSelf: [null, 'end'],
      gap: [4, 3],
      svg: {
        display: 'block',
        width: ['40px', '30px'],
        height: ['40px', '30px'],
      },
      mb: [2, 0],
    },
  }),
  map: {
    pinWrapper: {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%, 0)',
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent',
      borderTop: '6px solid',
    },
    pinContent: {
      position: 'absolute',
      borderRadius: radii.default,
      bottom: '6px',
      transform: 'translate(-50%, 0)',
      padding: '6px 8px',
      cursor: 'pointer',
      boxShadow: shadows.cardShadow,
      color: 'white',
      ...FONT_SIZE.SMALL,
    },
  },
  mapRadiusSearch: sxGroup({
    autocompleteControls: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      position: 'absolute',
      top: '10px',
      left: '10px',
      pointerEvents: 'auto',
    },
    radiusInput: {
      marginLeft: '-1px',
      '.input': {
        height: '50px',
      },
    },
    pin: {
      '.map-pin-content': {
        backgroundColor: colors.bgDarkBlueSecond,
      },
      '.map-pin-wrapper:after': {
        borderTopColor: colors.bgDarkBlueSecond,
      },
    },
    locationIcon: {
      display: 'inline-block',
      pr: 2,
      my: -4,
    },
  }),
  text: sxGroup({
    groupHeading: {
      ...FONT_SIZE.M,
      borderTop: '1px solid',
      borderColor: 'black',
      paddingTop: [1, '13px'],
    },
    secondaryHeading: {
      fontSize: fontSizes[3],
      marginBottom: space[2],
      [media.tablet]: {
        fontSize: fontSizes[4],
      },
    },
    success: {
      color: colors.success,
    },
    error: {
      color: colors.primary,
    },
    textBlock: sx({
      '.text-block': {
        py: ['30px', '75px'],
        mb: [null, 3],
        px: [4, 0],
      },
    }),
    textBlockWrap: sx({
      maxWidth: [null, '770px'],
      mx: [null, 'auto'],
    }),
    richText: sx({
      p: {
        pb: 3,
      },
      a: {
        display: 'inline',
        textDecoration: 'underline',
        textDecorationColor: 'rgba(0, 0, 0, 0.5)',
        '&:hover,&:focus': {
          color: colors.defaultText,
          textDecorationColor: colors.defaultText,
        },
      },
      'a[href^="tel:"]': {
        color: colors.mainRed,
        textDecoration: 'none',
        textDecorationColor: colors.mainRed,
        '&:hover,&:focus': {
          color: colors.mainRed,
          textDecoration: 'underline',
        },
      },
      ul: {
        pb: 2,
        li: {
          listStyle: 'disc',
          ml: ['20px', '24px'],
        },
      },
      ol: {
        pb: 2,
        li: {
          listStyle: 'decimal',
          ml: ['20px', '23px'],
        },
      },
      h2: {
        ...FONT_SIZE.M,
        pb: 3,
        pt: 3,
      },
      h3: {
        ...FONT_SIZE.S,
        pb: 3,
      },
      '.text-block-title': FONT_SIZE.M,
      '.text-block-text': FONT_SIZE.LARGE,
    }),
  }),
  link,
  responsiveMediaGallery: {},
  metaModules: {
    cookies: sxGroup({
      wrap: {
        borderTop: '1px solid',
        borderColor: 'lightGreyFirst',
        boxShadow: '8px 0 8px 0 rgba(0, 0, 0, 0.05)',
        position: 'fixed',
        bottom: '0',
        left: '0',
        right: '0',
        zIndex: '10',
        width: '100%',
        backgroundColor: 'bgLightBlue',
        flexWrap: ['wrap', 'nowrap'],
        justifyContent: [null, 'space-between'],
        alignItems: 'center',
      },
      text: {
        ...FONT_SIZE.LARGE,
        pl: [4, 6],
        pr: 4,
        py: [3, 4],
        a: {
          textDecoration: 'underline',
          textDecorationColor: colors.linkUnderlineColor,
          textUnderlineOffset: '4px',
          color: colors.defaultText,
          transition: 'none',
          transform: 'translate(0)',
          '&:hover,&:focus': {
            color: colors.defaultText,
            textDecorationColor: colors.defaultText,
            transform: 'scale(1)',
          },
        },
      },
      acceptButton: {
        width: ['100%', 'auto'],
        flexShrink: '0',
        borderRadius: ['0px', 'default'],
        mr: [null, 6],
        letterSpacing: '0.8px',
      },
    }),
  },
  helpOverlay: sxGroup({
    container: {
      display: 'grid',
      gap: '30px',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: 'auto auto auto',
      bg: 'bgLightBlue',
      pt: ['30px', '60px'],
      pb: [4, '60px'],
      px: ['60px', 4],
    },
    wrapper: {
      position: 'relative',
      width: '100%!important',
      '&.overlay-box': {
        display: 'grid',
        '.info-card': {
          borderRadius: '0',
          border: 'none',
          boxShadow: 'none',
          padding: 4,
          textAlign: 'left',
          h4: {
            ...FONT_SIZE.S,
            textAlign: 'left',
            fontWeight: '400',
          },
        },
      },
      // b2c
      '&.overlay-box:nth-of-type(1)': {
        gridColumnStart: '1',
        gridColumnEnd: '2',
        gridRowStart: '1',
        gridRowEnd: '2',
      },
      '&.overlay-box:nth-of-type(2)': {
        gridColumnStart: '1',
        gridColumnEnd: '2',
        gridRowStart: '2',
        gridRowEnd: '3',
      },
      '&.overlay-box:nth-of-type(3)': {
        gridColumnStart: '2',
        gridColumnEnd: '3',
        gridRowStart: '1',
        gridRowEnd: '3',
      },
      // b2b
      '&.overlay-box:nth-of-type(2).b2b': {
        gridColumnStart: '2',
        gridColumnEnd: '3',
        gridRowStart: '1',
        gridRowEnd: '2',
      },
      '&.overlay-box:nth-of-type(3).b2b': {
        gridColumnStart: '1',
        gridColumnEnd: '2',
        gridRowStart: '2',
        placeSelf: 'start',
      },
    },
    schedule: {
      display: 'flex',
      flexDirection: 'column-reverse',
      '.help-overlay-opening-hours-title': {
        display: 'none',
      },
      '> p': {
        display: 'block',
        ...FONT_SIZE.MEDIUM,
        fontWeight: 'normal',
        color: 'black!important',
        textTransform: 'lowercase',
        '&:before': {
          content: '" "',
          display: 'inline-block',
          width: '8px',
          height: '8px',
          borderRadius: '100%',
          mr: 2,
        },
      },
      '.help-overlay-opening-hours-item': {
        display: 'inline-block',
        color: 'darkGray',
        '&:not(:last-child):after': {
          content: '"|"',
          display: 'inline',
          mx: '4px',
        },
      },
      '&.opened': {
        '> p': {
          '&:before': {
            backgroundColor: 'success',
          },
        },
      },
      '&.closed': {
        '> p': {
          '&:before': {
            backgroundColor: 'error',
          },
        },
      },
    },
    close: {
      gridColumnStart: '1',
      gridColumnEnd: '3',
      justifyContent: 'flex-end!important',
      mt: '-6px',
      p: '0',
      gap: 2,
      '.icon': {
        width: '12px',
        height: '12px',
        '&:hover, &:focus': {
          color: 'black',
        },
      },
    },
  }),
  counter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '35px',
    height: '35px',
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '50%',
    fontSize: fontSizes[2],
    boxShadow: shadows.large,
    position: 'absolute',
  },
  buttons,
  header: sxGroup({
    wrap: {
      borderBottom: '1px solid',
      borderColor: colors.borderColor,
      position: 'relative',
      '.markup-control-button': {
        span: {
          height: '34px',
        },
      },
    },
    logo: {
      flexShrink: 0,
      img: {
        width: '98px',
        height: '34px',
        display: 'block',
        [media.tablet]: {
          width: '130px',
          height: '45px',
        },
      },
    },
    body: {
      display: 'flex',
      maxWidth: '1350px',
      minHeight: '60px',
      alignItems: 'center',
      padding: [`0 ${space[3]}`, `0 ${space[4]}`],
      margin: '0 auto',
      [media.tablet]: {
        minHeight: '64px',
      },
      '.language-control': {
        '.lang-button, .lang-dropdown-link': {
          height: '64px',
          width: '100%',
          px: 3,
        },
      },
      '.sub-links-list': {
        top: '65px',
      },
    },
  }),
  footer: sxGroup({
    heading: {
      ...FONT_SIZE.S,
      textAlign: [null, 'start'],
    },
  }),
  inputs: sxGroup({
    numericControlWrap: sx({
      display: 'flex',
      flexShrink: '0',
      width: ['120px', '135px'],
      justifyContent: 'space-between',
      '.input': {
        p: '0px',
        background: 'transparent',
        width: '25px',
        textAlign: 'center',
        mx: '13px',
        ...FONT_SIZE.LARGE_BOLD,
      },
    }),
    numericControl: sx({
      p: 0,
      color: 'black',
      '.icon': {
        height: ['30px', '38px'],
        width: ['30px', '38px'],
      },
      letterSpacing: 'initial',
      ':disabled': {
        opacity: 0.3,
        '&:hover, &:focus': {
          color: 'initial',
        },
      },
    }),
    baseInput: sx2CssTheme(basicTheme, {
      border: '1px solid',
      borderColor: 'borderColor',
      bg: 'bgInput',
      borderRadius: '0px',
      width: '100%',
      py: 2,
      px: ['12px', '20px'],
      ...FONT_SIZE.LARGE,
      height: '50px',
      '::placeholder': {
        color: colors.secondary,
      },
      letterSpacing: 'inherit',
      ':disabled': {
        background: colors.lightGreyFirst,
        cursor: 'default',
      },
    }),
    baseInputIcon: sx({
      left: ['14px', '20px'],
    }),
    hasIconMargin: sx({
      pl: ['44px', '52px'],
    }),
    select: {
      border: '1px solid',
      borderColor: colors.borderColor,
      borderRadius: 0,
      background: colors.bgInput,
      width: '100%',
      ...FONT_SIZE.LARGE,
      paddingTop: ['13px', '11px'],
      paddingBottom: ['13px', '11px'],
      paddingLeft: ['10px', '13px'],
      paddingRight: space[5],
      appearance: 'none',
      color: 'inherit',
      '::-ms-expand': {
        display: 'none',
      },
    },
    error: {
      '.status-icon': {
        color: colors.primary,
        right: '35px',
        left: 'auto',
        top: 'auto',
        bottom: '1px',
        ...icons.lg,
      },
      '.input': {
        borderColor: colors.error,
        paddingLeft: '12px',
        paddingRight: '45px',

        '~ .icon': {
          color: colors.error,
          right: '15px',
          left: 'auto',
          ...icons.lg,
        },
        [media.tablet]: {
          paddingLeft: '20px',
          paddingRight: '52px',
          '~ .icon': {
            right: '20px',
          },
        },
      },
    },
    success: {
      '.status-icon': {
        color: colors.success,
        right: '35px',
        left: 'auto',
        top: 'auto',
        bottom: '1px',
        ...icons.lg,
      },
      '.input': {
        borderColor: colors.success,
        paddingLeft: '12px',
        paddingRight: '45px',
        '~ .icon': {
          color: colors.success,
          right: '15px',
          left: 'auto',
          ...icons.lg,
        },
        [media.tablet]: {
          paddingLeft: '20px',
          paddingRight: '52px',
          '~ .icon': {
            right: '20px',
          },
        },
      },
    },
    searchControlField: sx2CssTheme(basicTheme, {
      '.icon': {
        width: '20px',
        height: '20px',
        mt: '-1px',
      },
      input: {
        height: ['45px', '48px'],
        pr: [null, '5px'],
      },
      'input:focus': {
        boxShadow: `inset 0 -3px 0 0 ${colors.primary}`,
      },
    }),
    travelDatesWrapper: sx({
      position: 'relative',
    }),
    textArea: sxGroup({
      wrapper: {
        '.label': {
          pl: '5px',
          color: 'secondary',
          mb: [1, 2],
        },
      },
      input: {
        border: '1px solid',
        borderColor: 'borderColor',
        bg: 'bgInput',
        width: '100%',
        fontSize: [2, 3],
        py: [2, 3],
        px: ['10px', '20px'],
      },
    }),
  }),
  auth: sxGroup({
    b2bLoginLayout: {
      backgroundColor: 'bgLightBlue',
      pt: [0, '45px'],
      pb: [0, '75px'],
      '.error': {
        mt: -3,
        mb: 3,
      },
    },
    loginSocialButton: {
      border: 'none',
      p: 0,
      display: 'flex',
      alignItems: 'center',
      height: ['52px', '55px'],
      overflow: 'hidden',
      width: ['100%', '310px'],
      '.login-social-text': {
        pl: [5, '18px'],
        ...FONT_SIZE.LARGE,
      },
      '.login-social-icon': {
        width: ['52px', '55px'],
        height: ['52px', '55px'],
        padding: '16px',
      },
    },
    loginSocialWrapper: {
      display: 'flex',
      gap: [3, '35px'],
      justifyContent: 'center',
      width: '100%',
      py: [4, '40px'],
      flexDirection: ['column', 'row'],
      alignItems: 'center',
      px: [4, 0],
      '.facebook-login-button': {
        ':focus:not([disabled]),:hover:not([disabled]),:focus-visible:not([disabled])': {
          border: 'none',
          backgroundColor: 'facebookButtonBackground',
          '.icon': {
            backgroundColor: 'facebookIconBackground',
          },
        },
      },
      '.google-login-button': {
        ':focus:not([disabled]),:hover:not([disabled]),:focus-visible:not([disabled])': {
          border: 'none',
          backgroundColor: 'googleButtonBackground',
          '.icon': {
            backgroundColor: 'googleIconBackground',
          },
        },
      },
    },
    loginSocialTop: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      px: [4, 0],
      '.decoration-line-span': {
        height: '1px',
        width: '100%',
        display: 'block',
        borderTop: '1px solid',
        borderColor: 'borderColor',
        flexGrow: 1,
      },
      '.or-span': {
        pt: [null, 2],
        px: [2, 3],
        color: 'darkGray',
        flexShrink: '0',
        lineHeight: ['1.3', '1'],
        fontSize: [1, 2],
      },
    },
    panelContent: {
      position: 'relative',
      px: [null, 1],
    },
    b2cLoginLinkWrapper: {
      'button, a': FONT_SIZE.MEDIUM,
    },
    errorMessage: {
      mb: 3,
      color: colors.error,
    },
    formTitleStyles: {
      ...FONT_SIZE.S,
      mb: [4, '25px'],
    },
    formTextStyles: {
      ...FONT_SIZE.LARGE,
      mb: [4, '25px'],
    },
    loginLayoutBlockStyles: {
      maxWidth: [null, '970px'],
      backgroundColor: 'white',
      boxShadow: [null, 'blockShadow'],
      mx: [null, 'auto'],
      flexWrap: 'wrap',
      '.layout-text': {
        ...FONT_SIZE.LARGE,
        mb: [4, '50px'],
      },
    },
    formModalWrapperStyles: {
      width: '100%',
      maxWidth: '450px',
      position: 'relative',
      height: [null, 'auto'],
      borderRadius: ['roundedTop', '0px'],
      '.modal-body, .modal-header': {
        backgroundColor: 'white',
      },
    },
    loginForm: {
      '.text-input': {
        mb: [3, 4],
        '&:last-of-type': {
          mb: 4,
        },
      },
      '.login-form-submit': {
        width: '100%',
        marginBottom: [3, '20px'],
      },
      '.login-form-forgot-button': {
        py: 2,
      },
    },
  }),
  brandLogos: sxGroup({
    items: {
      px: [2, 2, 0],
      pb: [2, 0],
      mb: [3, 4, 0],
      '@media (max-width: 1335px)': {
        ml: 'auto',
        mr: 0,
        width: [null, 'calc(50% - 24px)'],
      },
    },
    imageLink: {
      padding: space[1],
      '&:not(:last-child)': {
        marginRight: space[3],
        [media.tablet]: {
          marginRight: '40px',
        },
      },
    },
  }),
  tooltip: {
    whiteSpace: 'nowrap',
    top: '-87%',
    left: '50%',
    transform: 'translateX(-50%)',
    background: 'white',
    borderRadius: radii.default,
    position: 'absolute',
    visibility: 'hidden',
    border: `1px solid ${colors.lightGreyFirst}`,
    padding: '10px 12px',
    fontSize: fontSizes[1],
    fontWeight: 'bold',
    ':after': {
      content: "''",
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '8px 10px 0 10px',
      borderColor: 'white transparent transparent transparent',
      position: 'absolute',
      bottom: '-7px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    ':before': {
      content: "''",
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '8px 10px 0 10px',
      borderColor: `${colors.lightGreyFirst} transparent transparent transparent`,
      position: 'absolute',
      bottom: '-8px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    '+ .tooltipArrow': {
      zIndex: '12!important',
    },
  },
  customTooltip: sxGroup({
    body: {
      overflow: 'hidden',
    },
    wrapper: {
      overflow: 'hidden',
      maxHeight: 'none',
    },
    arrow: {
      left: '50%',
      top: '-1px',
      width: '10px',
      height: '10px',
      display: 'block',
      position: 'absolute',
      visibility: 'hidden',
      transform: 'translateX(-50%)',
      ':after': {
        content: "''",
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '8px 10px 0 10px',
        borderColor: 'white transparent transparent transparent',
        position: 'absolute',
        bottom: '13px',
        left: '50%',
        transform: 'translateX(-50%)',
      },
      ':before': {
        content: "''",
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '8px 10px 0 10px',
        borderColor: `${colors.lightGreyFirst} transparent transparent transparent`,
        position: 'absolute',
        bottom: '12px',
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },
    header: {
      display: [null, 'flex'],
      alignItems: [null, 'center'],
      position: 'relative',
      gap: 3,
      '.tooltip': {
        whiteSpace: 'nowrap',
        transform: 'translateX(-50%)',
        background: 'white',
        borderRadius: radii.default,
        position: 'absolute',
        display: 'block',
        visibility: 'hidden',
        border: `1px solid ${colors.borderColor}`,
        padding: '10px 12px',
        fontSize: fontSizes[1],
        fontWeight: 'bold',
        bottom: '-45%',
        left: '50%',
        zIndex: 100,
        lineHeight: '1',
        '@media screen and (max-width: 1040px)': {
          padding: '10px 6px',
        },
      },
      ':hover .tooltip': {
        visibility: [null, `visible`],
        display: 'block',
        backgroundColor: 'white',
        position: 'absolute',
        '&:after': {
          content: "''",
          width: 0,
          height: 0,
          borderStyle: 'solid',
          borderWidth: '8px 10px 0 10px',
          borderColor: 'white transparent transparent transparent',
          position: 'absolute',
          top: '-7px',
          left: '50%',
          transform: 'translateX(-50%) rotate(180deg)',
        },
        '&:before': {
          content: "''",
          width: 0,
          height: 0,
          borderStyle: 'solid',
          borderWidth: '8px 10px 0 10px',
          borderColor: `${colors.lightGreyFirst} transparent transparent transparent`,
          position: 'absolute',
          top: '-8px',
          left: '50%',
          transform: 'translateX(-50%) rotate(180deg)',
        },
      },
    },
    FeatureIconWrapper: {
      marginRight: 2,
      verticalAlign: [`sub`, `middle`],
      '.feature-icon': {
        padding: `2px 3px ${space[1]} 2px`,
        color: colors.success,
      },
    },
  }),
  hero: sxGroup({
    wrap: {
      height: ['185px', '520px', '520px'],
    },
  }),
  tripAdvisor: sxGroup({
    small: {
      icon: {
        width: '15px',
        height: '15px',
      },
      image: {
        objectFit: 'contain',
        height: '18px',
        width: '23px',
        [media.tablet]: {
          height: '27px',
          width: '45px',
        },
        [media.desktop]: {
          height: '27px',
          width: '45px',
        },
      },
      text: {
        fontWeight: 'normal',
      },
    },
    medium: {
      icon: {
        width: '16px',
        height: '16px',
      },
      image: {
        height: '30px',
        width: '50px',
        [media.tablet]: {
          height: '35px',
          width: '60px',
        },
        [media.desktop]: {
          height: '35px',
          width: '60px',
        },
      },
      text: {
        fontWeight: 'bold',
      },
    },
    detailsWrapper: {
      py: [4, '65px'],
      mx: [4, 0],
      '> .-info': {
        mb: 2,
      },
      '.-info-wrapper': {
        mb: [2, 3],
      },
    },
    detailsInfo: {
      flexDirection: ['column', 'row'],
      alignItems: [null, 'center'],
      fontSize: [null, '25px'],
      '.icon': {
        variant: ['icons.md', 'icons.md'],
        mr: [null, 2],
      },
      '.tripAdvisorInfoText': {
        ...FONT_SIZE.LARGE,
        pt: ['13px', 0],
        ml: [null, 4],
      },
      '.tripAdvisorInfoNumber': {
        ...FONT_SIZE.LARGE_BOLD,
      },
    },
    detailedReview: {
      marginBottom: space[2],
      padding: `${space[3]} 0`,
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderColor: colors.borderColor,
      [media.tablet]: {
        marginBottom: space[4],
      },
      '> h2': {
        ...FONT_SIZE.M,
        mb: ['10px', 3],
      },
      '> .rating-wrap': {
        display: 'flex',
        flexDirection: ['column', 'row'],
        flexWrap: 'wrap',
        gap: [null, '5px 0'],
      },
    },
    currentRatings: {
      '> h2': {
        ...FONT_SIZE.M,
        mb: [4, '45px'],
      },
    },
    detailsRating: {
      mr: [null, 5],
      mb: ['12px', 0],
      '.icon': {
        variant: [null, 'icons.md'],
        mr: [null, '6px'],
      },
    },
    ratingWrap: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      ...FONT_SIZE.LARGE,
    },
    copyrights: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      fontSize: fontSizes[1],
      [media.tablet]: {
        fontSize: fontSizes[2],
      },
      '> .-year': {
        paddingRight: space[2],
        [media.tablet]: {
          paddingRight: space[1],
        },
      },
      '> .-powered': {
        display: 'flex',
        alignItems: 'center',

        '> .-powered-text': {
          marginRight: space[2],
        },
      },
    },
    logo: {
      height: '20px',
      width: '120px',
    },
    reviewHeader: {
      width: ['100%', `${(3 / 10) * 100}%`],
      mb: [2, 1],
      pr: [2, 3],
      '.review-description': {
        ...FONT_SIZE.LARGE,
        color: 'darkGray',
        pt: 2,
        mb: 1,

        '.review-author, .review-region, .review-date': {
          '&:not(:last-child):after': {
            display: 'inline-block',
            content: '"/"',
            pl: 1,
            mr: 1,
          },
        },
      },
    },
    moreLessButton: {
      ...FONT_SIZE.LINK_LARGE,
    },
    commentTitle: {
      ...FONT_SIZE.XS,
      mb: [2, 3],
    },
    commentText: {
      ...FONT_SIZE.LARGE,
      letterSpacing: [null, '0.7px'],
      whiteSpace: 'pre-wrap',
      mb: 1,
    },
  }),
  menu: sxGroup({
    nav: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      ':hover': {
        color: colors.interactionPrimary,
      },
    },
    headerMenu: {
      width: ['100%', 'auto'],
      backgroundColor: 'background',
      mb: [4, 0],
      '.mobile-nav-bar-list': {
        pb: '10px',
      },
      alignItems: 'center',
      '> ul li:first-child': {
        pl: 0,
      },
      '.menu-link-item:has(+ .sub-links-list:hover), .menu-link-item:has(+ .sub-links-list:focus-within)': {
        color: colors.primary,
      },
    },
    menuLinkItem: {
      display: 'flex',
      height: '64px',
      pl: '24px',
      ':hover': {
        color: colors.interactionPrimary,
      },
      '&:hover + .sub-links-list, &:focus-within + .sub-links-list': {
        zIndex: '999',
        opacity: '1',
      },
    },
    subLinksListWrap: {
      position: 'absolute',
      backgroundColor: colors.background,
      borderRight: `1px solid ${baseColors.gray[1]}`,
      borderBottom: `1px solid ${baseColors.gray[1]}`,
      borderLeft: `1px solid ${baseColors.gray[1]}`,
      width: '100%',
      flexDirection: 'column',
      maxWidth: '1317px',
      top: '101%',
      transform: 'translateX(-50%)',
      left: '50%',
      py: 5,
      opacity: '0',
      zIndex: '-1000',
      '&:hover, &:focus-within, &:focus, &:focus-visible': {
        zIndex: '999',
        opacity: '1',
      },
    },
    subLinksList: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      maxWidth: '1204px',
      width: '100%',
      maxHeight: '330px',
      mx: 'auto',
      '@media screen and (max-width: 1240px)': {
        pl: '16px',
      },
    },
    navBarItem: {
      maxWidth: '380px',
      width: '100%',
      mr: '34px',
      '@media screen and (max-width: 1240px)': {
        maxWidth: '360px',
      },
      '@media screen and (max-width: 1180px)': {
        maxWidth: '300px',
      },
      '@media screen and (max-width: 981px)': {
        maxWidth: 'initial',
      },
    },
    navBarLink: {
      py: '10px',
      pr: [0, 2],
      mb: '-1px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      ...FONT_SIZE.NAVIGATION,
      lineHeight: [null, '1.3'],
      '.icon': {
        fill: 'none',
        color: colors.primary,
        height: '24px',
        width: '24px',
      },
      ':hover': {
        color: colors.interactionPrimary,
        '.icon': {
          color: colors.interactionPrimary,
        },
      },
    },
    mobileMenuModal: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: '56px',
      bottom: 0,
      left: 0,
      width: ['100%', '40%'],
      backgroundColor: colors.background,
      px: 3,
      pt: 4,
      pb: 8,
      height: ['calc(100vh - 56px)', 'calc(100vh - 79px)'],
      zIndex: '999',
      overflowY: 'auto',
      borderTop: '1px solid',
      borderColor: 'borderColor',
      '.language-control': {
        mb: 4,
        '.input': {
          ...FONT_SIZE.LARGE,
          pl: 0,
          py: '10px',
        },
      },
    },
  }),
  tabs: sxGroup({
    tabControlsWrap: {
      borderColor: colors.lightGreySecond,
      flexGrow: 1,
      position: 'relative',
      '.checked': {
        borderBottomColor: colors.primary,
        color: colors.primary,
        '&:hover': {
          color: colors.primary,
          borderBottomColor: colors.primary,
        },
      },
    },
    tabControl: {
      ...FONT_SIZE.TABS,
      flexDirection: ['column', 'row'],
      justifyContent: 'center',
      alignItems: 'center',
      height: ['50px', '58px'],
      flexGrow: [1, 0],
      letterSpacing: ['0.3px', '0.5px'],
      backgroundColor: 'white',
      borderBottomWidth: ['2px', '3px'],
      borderBottomColor: 'transparent',
      borderBottomStyle: 'solid',
      transition: 'fast',
      '.icon': { width: ['48px', '24px'], height: ['20px', '24px'] },
      [media.mobile]: {
        textAlign: 'center',
        px: 1,
      },
      '.icon-wrap': {
        display: 'flex',
        mb: '2px',
        mr: [null, '10px'],
      },
      '.tab-label': {
        whiteSpace: [null, 'nowrap'],
      },
      '&:hover': {
        color: colors.interactionPrimary,
        borderBottomColor: colors.interactionPrimary,
      },
    },
  }),
  mediaGallery: sxGroup({
    mediaGalleryStyles: {
      '.slider-control-centerleft': {
        left: '25px!important',
      },
      '.slider-control-centerright': {
        right: '25px!important',
      },
    },
    mediaGalleryModal: {
      '.modal-body': {
        maxWidth: '1185px',
        width: '100%',
        mx: 'auto',
        p: 4,
        py: [4, '40px'],
      },
    },
    slideInfo: {
      color: 'darkGray',
    },
  }),
  notifications: sxGroup({
    notificationInfo: {
      display: 'flex',
      flexShrink: 0,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '0px',
      borderBottom: '1px solid',
      borderColor: 'lightGreyFirst',
      py: 1,
      'a:hover': {
        color: 'primary',
      },
      overflow: 'hidden',
    },
    notificationInfoContent: {
      '*, p': {
        fontSize: 1,
      },
    },
    mantainanceNotification: {
      position: 'fixed',
      bottom: ['0px', 5],
      left: [null, '50%'],
      transform: [null, 'translate(-50%)' as const],
      width: '100%',
      maxWidth: '770px',
      borderRadius: 'default',
      zIndex: '10',
      overflow: [null, 'hidden'],
      boxShadow: 'notificationShadow',
      '.notification-body': {
        backgroundColor: 'white',
        color: 'black',
      },
      variant: 'text.richText',
      'p:last-child': {
        mb: 0,
      },
    },
  }),
  agency: sxGroup({
    mapSearch: {
      wrapper: {
        position: 'relative',
        paddingBottom: [null, '70px'],
        mb: [null, '47px'],
      },
      box: {
        position: 'absolute',
        width: 'calc(100vw - 50px)',
        maxWidth: [null, '970px'],
        backgroundColor: 'white',
        top: ['23px', 'auto'],
        bottom: [null, '-15px'],
        left: '50%',
        transform: 'translateX(-50%)',
        borderRadius: 'default',
        boxShadow: '0 2px 7px 2px rgba(0,0,0,0.22)',
        zIndex: 2,
        pb: [null, 3],
        pt: [null, '30px'],
        px: [null, '30px'],
        '.map-search-controls': {
          mb: [null, '15px'],
        },
      },
      geo: {
        ...FONT_SIZE.LINK_LARGE,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '.icon': {
          marginRight: '10px',
        },
      },
    },
  }),
  autocomplete: sxGroup({
    mobileModal: {},
    loadMoreButton: {
      ...FONT_SIZE.LINK_LARGE,
      color: colors.defaultText,
    },
    tagBlock: {
      flexDirection: 'column',
      flex: '1 auto',
      marginY: ['15px', 0],
      maxHeight: ['100%', '50vh'],
      overflowY: ['none', 'auto'],
      px: [null, 3],
      pt: [null, '14px'],
      width: [null, '100%'],
      backgroundColor: [null, 'background'],
      ...FONT_SIZE.LARGE,
      boxShadow: [null, '0 3px 5px 1px rgba(0,0,0,0.06)'],
      border: ['none', '1px solid'],
      borderColor: [null, 'borderInput'],
      borderTop: ['none', 'none'],
    },
    inputWrapStyles: {
      border: '1px solid',
      borderColor: 'borderColor',
      backgroundColor: 'bgInput',
      flexWrap: 'wrap',
      alignItems: 'center',
      p: [1, 0],
      input: {
        width: '100%',
        border: 'none',
        [media.tablet]: {
          height: '48px',
        },
      },
    },
    suggestionListStyles: {
      borderBottom: '1px solid ',
      borderColor: 'lightGreyFourth',
      '.autocomplete-option > .-icon': {
        mr: '14px',
      },
      pb: 2,
    },
    tagWrap: {
      display: 'inline-flex',
      alignItems: 'center',
      py: 1,
      px: 2,
      border: '1px solid',
      borderColor: 'borderInput',
      borderRadius: 'default',
      backgroundColor: 'background',
      m: 1,
      mr: 0,
      color: 'secondary',
    },
    autocompleteRemoveButtonStyles: {
      ml: 1,
      color: 'darkGray',
    },
  }),
  tag: {
    ...sxGroup({
      removableTagWrapper: {
        transition: 'border-color 0.2s ease-in',
        '.removableTagLabel': {
          textDecoration: 'underline 1px rgba(0,0,0,0)',
          textUnderlineOffset: '2px',
          transition: 'text-decoration-color 0.2s ease-in',
        },
        '&:hover': {
          borderColor: baseColors.black,
          '.removableTagLabel': {
            textDecorationColor: baseColors.black,
          },
        },
        '&:focus': {
          borderColor: baseColors.black,
          px: '7px',
          borderWidth: '2px',
          '.removableTagLabel': {
            textDecorationColor: baseColors.black,
          },
        },
      },
    }),
  },
  catalogs: sxGroup({
    catalogsHeading: {
      mb: [4, 5],
      mx: [4, 0],
    },
    catalogsOrderHeading: {
      mb: [0, 3],
    },
    catalogFormSubmitButton: {
      width: ['100%', '430px'],
      position: 'relative',
    },
    catalogForm: {
      'fieldset+.error': {
        mt: -3,
      },
      '.privacy-link': {
        color: `${colors.defaultText}!important`,
        textDecoration: 'underline',
        textDecorationColor: colors.linkUnderlineColor,
        textUnderlineOffset: '6px',
        transition: 'none',
        '&:hover,&:focus': {
          color: colors.defaultText,
          textDecorationColor: colors.defaultText,
        },
      },
    },
    catalogsFormContent: {
      display: 'flex',
      mx: '-5px',
      flexWrap: 'wrap',
      mb: ['20px', 0],
    },
    catalogsListWrap: {
      p: '6px',
      pb: 1,
      '.content': {
        display: 'flex',
        justifyContent: 'space-between',
        color: 'primary',
        p: 1,
        '.catalog-links, .link-wrapper': {
          display: 'flex',
          justifyContent: 'space-between',
        },
      },
      a: {
        fontSize: '0px',
      },
    },
    catalogList: {
      display: 'grid',
      gridTemplateColumns: ['1fr 1fr', '1fr 1fr 1fr 1fr'],
      rowGap: ['20px', 3],
      columnGap: ['18px', '30px'],
      mb: ['50px', '45px'],
      mx: [4, 0],
    },
    catalogsOrderedCatalogsList: {
      display: 'grid',
      gridTemplateColumns: ['1fr', '1fr 1fr'],
      rowGap: ['20px', 3],
      columnGap: ['18px', '30px'],
      mb: [2, 5],
    },
    catalogCardWrap: {
      a: {
        width: '100%',
      },
      '.content a': {
        width: 'auto',
      },
    },
    catalogsDownloadLink: {
      marginRight: space[2],
      display: 'flex',
      alignItems: 'center',
    },
    catalogCardInfoFormWrap: {
      display: 'flex',
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderColor: 'borderColor',
      py: ['10px', 3],
      maxWidth: '430px',
      width: '100%',
      mb: [5, '50px'],
      pr: 2,
      flexShrink: '0',
      '.imageWrap': {
        flexShrink: 0,
      },
    },
    catalogDeleteButtonWrap: {
      textAlign: 'end',
      pt: [3, 2],
      lineHeight: 1,
      color: 'primary',
    },
    catalogCardInfoImage: {
      display: 'block',
      width: ['75px', '87px'],
      height: '100%',
      objectFit: `cover`,
      mr: 3,
    },
    catalogCardInfoFormContent: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      '.wrapper': {
        width: '100%',
      },
      '.heading': {
        fontSize: [2, 3],
        fontWeight: 'normal',
        mb: [1, 2],
      },
      '.catalogDates': {
        fontSize: [1, 2],
        color: 'secondary',
      },
    },
  }),
  forms: sxGroup({
    label: {
      pl: ['5px'],
      color: 'darkGray',
      mb: [1, 2],
      span: {
        ml: 1,
      },
    },
    unsubscribeForm: {
      form: {
        maxWidth: '770px',
        p: '30px',
        mx: 'auto',
        mb: [null, '60px'],
        border: '1px solid',
        borderColor: 'lightGreySecond',
        borderRadius: 'default',
        '.unsubscribe-text-input': {
          mb: 4,
          '.input': {
            backgroundColor: 'bgLightBlue',
          },
        },
      },
      checkboxes: {
        mb: [3, '20px'],
        '.label': {
          color: 'initial',
          pl: 0,
        },
      },
    },
    filtersForm: {
      display: ['grid', 'flex'], // fix firefox sticky covering issue
      flexDirection: 'column',
      maxHeight: '100%',
    },
    fullNameWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    bannerFormWrap: {
      backgroundColor: [null, 'bgLightBlue'],
      pt: [0, '120px'],
      position: [null, 'relative'],
      display: [null, 'flex'],
      flexDirection: [null, 'column'],
    },
    bannerForm: {
      position: [null, 'absolute'],
      top: [null, '40px'],
      maxWidth: [null, '1170px'],
      width: [null, '100%'],
      left: [null, '50%'],
      transform: [null, 'translate(-50%)'],
      zIndex: '3',
    },
    staticBannerForm: {
      mx: 'auto',
    },
    shortBannerForm: {
      position: 'static',
      transform: 'none',
      borderRadius: 'default',
      boxShadow: '0 2px 4px 2px rgba(0,0,0,0.06)',
      mb: [null, '20px'],
      '.form-container': {
        textAlign: 'center',
        pt: 3,
        px: [null, '24px'],
        pb: 3,
        '& .input': {
          height: [null, '48px'],
          fontSize: [null, 2],
        },
        '& .input ~ .icon': {
          left: [null, '15px'],
        },
      },
      '.travelTypes': {
        mb: 0,
      },
      '.tabControls': {
        height: ['50px', '58px'],
        px: 4,
      },
      '.travelTypeItem': {
        flex: '0 0 auto',
      },
    },
    emailForm: {
      submitButtonWrapper: {
        display: 'flex',
        flexDirection: ['column', 'row-reverse'],
        justifyContent: 'space-between',
        p: {
          fontSize: 1,
          maxWidth: [null, '400px'],
        },
        '.form-submit-button': {
          width: ['100%', '140px'],
          marginLeft: 'auto',
          mb: [4, 0],
        },
        '.privacy-link': {
          color: `${colors.defaultText}!important`,
          textDecoration: 'underline',
          textDecorationColor: colors.linkUnderlineColor,
          textUnderlineOffset: '6px',
          transition: 'none',
          '&:hover,&:focus': {
            color: colors.defaultText,
            textDecorationColor: colors.defaultText,
          },
        },
      },
      maxWidth: '770px',
      p: '30px',
      mx: 'auto',
      mb: [null, '60px'],
      border: '1px solid',
      borderColor: 'lightGreySecond',
      borderRadius: 'default',
      '.radio-group-input': {
        div: {
          display: 'flex',
          flexDirection: 'row',
          mb: 3,
        },

        '.label': {
          mr: 4,
          pl: 0,
        },
      },
      '.text-input': {
        marginBottom: 4,
      },
      '.error': {
        mt: -3,
        mb: 3,
      },
    },
    registrationForm: {
      '.error-message-wrap': {
        my: 3,
        '.account-already-exists-error': {
          color: 'primary',
        },
      },
      '.checkbox-input-wrap': {
        mb: 3,
        '.label': {
          color: 'initial',
          pl: 0,
        },
      },
      '.registration-login-link': {
        fontSize: 2,
        fontWeight: 600,
        display: 'inline-block !important',
      },
      '.submit-button-wrapper': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: [2, 3],
        flexDirection: ['column', 'row-reverse'],
        justifyContent: 'space-between',
        p: {
          fontSize: 1,
        },
        '.form-submit-button': {
          width: ['100%', '140px'],
          marginLeft: 'auto',
        },
      },
      maxWidth: '770px',
      p: '30px',
      mx: 'auto',
      mb: [null, '60px'],
      border: '1px solid',
      borderColor: 'lightGreySecond',
      borderRadius: 'default',
      '.radio-group-input': {
        div: {
          display: 'flex',
          flexDirection: 'row',
          mb: 3,
        },

        '.label': {
          mr: 4,
          pl: 0,
        },
      },
      '.text-input': {
        marginBottom: 3,
      },
      '.error': {
        mt: -3,
        mb: 3,
        color: 'error',
      },
    },
    customCheckboxControl: {
      height: ['25px', '20px'],
      width: ['25px', '20px'],
      position: 'relative',
      border: '1px solid',
      borderColor: 'borderColor',
      borderRadius: '2px',
      order: -1,
      marginRight: [2, '10px'],
      backgroundColor: 'white',
      flexShrink: 0,
      '&::after': {
        content: "''",
        position: 'absolute',
        opacity: 0,
        left: ['7px', '6px'],
        top: ['1px', '2px'],
        width: ['8px', '6px'],
        height: ['17px', '12px'],
        border: '1px solid',
        borderColor: 'primary',
        borderWidth: '0 2px 2px 0',
        transform: 'rotate(40deg)',
      },
    },
  }),
  modals: sxGroup({
    '.modal-title': FONT_SIZE.S,
    modalContentStyles: {
      flex: '1',
      flexDirection: 'column',
      p: 4,
      pb: 5,

      '> *': {
        flexShrink: '0',
      },
    },
    catalogErrorModal: {
      '.modal-body': {
        p: [4, 5],
      },
      '.modal-wrapper': smallModalWrapper,
      '.modal-shadow': {
        backgroundColor: colors.modalShadow,
      },
    },
    forgotPasswordModal: {
      '.modal-wrapper': {
        width: '100%',
        maxWidth: '450px',
        position: 'relative',
        height: [null, 'auto'],
        borderRadius: ['roundedTop', '0px'],
        '.modal-body, .modal-header': {
          backgroundColor: 'authModalBackgroundColor',
        },
        '.modal-header': {
          fontSize: [3, 4],
          px: 4,
        },
      },
    },
    wideModalWrapper: {
      maxWidth: [null, '970px'],
      borderRadius: ['roundedTop', '0px'],
      height: [null, 'auto'],
      '.modal-title': FONT_SIZE.S,
    },
    mediumModalWrapper: {
      maxWidth: [null, '572px'],
      borderRadius: 4,
      height: [null, 'auto'],
      '.modal-title': FONT_SIZE.S,
    },
    wideModalHeader: {
      py: [2, '14px'],
      px: ['20px', '36px'],
    },
    actionlessModalWrapper: {
      position: 'relative',
      maxWidth: [null, '970px'],
      borderRadius: 'default',
      mx: [4, 0],
      alignSelf: 'center',
    },
    smallModalWrapper: {
      width: ['100%', 'auto'],
      '.modal-title': {
        ...FONT_SIZE.S,
        mr: 3,
      },
    },
    commonModalBody: {
      px: [4, '40px'],
      pt: [4, '30px'],
      pb: [4, '40px'],
      '.tooltip': {
        '&:after': {
          right: '8px',
        },
        '&:before': {
          left: 'auto',
          right: '8px',
          transform: 'none',
        },
      },
    },
    confirmationControls: {
      width: '100%',
      flexDirection: ['column', 'row'],
      justifyContent: [null, 'flex-end'],
      px: [4, 0],
      button: {
        minWidth: '150px',
      },
      pb: 4,
    },
    notificationCardWrapWithError: {
      textAlign: 'center',
      backgroundColor: 'background',
      borderColor: 'borderGreyFifth',
      p: [null, 3],
      pt: 4,
      borderRadius: 'default',
      paddingTop: 0,
      boxShadow: 'none',
      '.notification-content': { maxWidth: '250px', margin: 'auto' },
      '.heading': {
        fontSize: 3,
        mb: 2,
      },
      '.text': {
        mb: 4,
      },
    },
    absoluteDropdownBox: {
      bg: 'background',
      top: '0',
      width: ['auto', '100%'],
      position: 'absolute',
      zIndex: '10',
      boxShadow: 'cardShadow',
    },
  }),
  travelDates: sxGroup({
    durationDropdownWrapper: {
      '.input': {
        py: 2,
        ...FONT_SIZE.MEDIUM,
        '&:focus-visible': {
          outline: 'auto 2px',
          outlineOffset: '-1px',
          outlineColor: baseColors.black,
        },
      },
      '.chevron': {
        bottom: '18px',
      },
    },
    travelDatesContainerCss: {
      width: '100%',
      flexShrink: 0,
      zIndex: 0,
      mt: ['48px !important', '0 !important'],
      pt: ['10px', '20px'],
      px: 3,

      '.DayPicker': {
        width: '100%',
      },

      '.DayPicker-wrapper': {
        outline: 'none',
      },

      '.DayPicker-NavBar': {
        button: {
          '&:focus-visible': {
            svg: {
              outline: 'auto 2px',
              outlineColor: baseColors.black,
            },
          },
        },
      },

      '.DayPicker-Months': {
        flexDirection: 'column',
      },

      '.DayPicker-Month': {
        width: '100%',
        margin: 0,
      },

      '.DayPicker-Month .DayPicker-Caption div': {
        ...FONT_SIZE.XS,
      },

      '.DayPicker-Month .DayPicker-Caption': {
        textAlign: 'right',
        margin: 0,
        padding: 0,
      },

      '.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled), .DayPicker-Day--inside-range:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled)': {
        backgroundColor: 'lightGreyFirst',
        color: 'black',
      },

      '.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside), .DayPicker-Day--inside-range:not(.DayPicker-Day--outside)': {
        backgroundColor: 'lightGreyFirst',
        color: 'black',
      },

      '.DayPicker-Day': {
        borderRadius: 0,
        height: '50px',
        width: '50px',
        outlineOffset: '-1px',
      },

      '.DayPicker-Day--today': {
        fontWeight: 'normal',
      },

      '.DayPicker-Day--today--disabled': {
        color: 'lightGreyFirst',
      },

      '.DayPicker-Day--disabled': {
        color: 'lightGreyFirst',
      },

      '.date-picker .DayPicker-Body .DayPicker-Week .DayPicker-Day--disabled.DayPicker-Day--selected:not(.DayPicker-Day--start)': {
        color: 'secondary',
      },

      '.DayPicker-Day--today:not(.DayPicker-Day--selected):not(.DayPicker-Day--outside)': {
        position: 'relative',
        color: 'black',
        zIndex: 1,
      },

      '.DayPicker-Day--today:not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):after': {
        content: '""',
        width: '30px',
        height: '30px',
        backgroundColor: colors.lightGreyFirst,
        borderRadius: '50%',
        display: 'block',
        position: 'absolute',
        zIndex: -1,
        // HACK: IE hack, transform is not working properly
        top: '10px',
        left: '9px',
      },

      '.date-picker .DayPicker-Body .DayPicker-Week .DayPicker-Day--start:not(.DayPicker-Day--outside)': {
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        backgroundColor: 'primary',
      },

      '.date-picker .DayPicker-Body .DayPicker-Week .DayPicker-Day--end:not(.DayPicker-Day--outside)': {
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        backgroundColor: 'primary',
      },

      '.DayPicker-Caption': {
        height: `24px`,
        lineHeight: `24px`,
      },

      '.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover': {
        backgroundColor: 'lightGreyFirst',
      },
    },
    bottomPanelCss: {
      boxShadow: 'none',
      position: 'sticky',
      display: ['initial', 'flex'],
      justifyContent: 'space-between',
      alignItems: 'center',
      py: [0, 2],
      px: 3,
      zIndex: '1',
      bottom: 0,
      borderTop: ['1px solid', 'none'],
      borderColor: 'lightGreyFirst',
      backgroundColor: 'background',
      '&:after': {
        content: ['', "''"],
        top: 0,
        position: 'absolute',
        borderTop: '1px solid',
        borderColor: 'lightGreyFirst',
        width: 'calc(100% - 32px )',
      },
      left: 0,
      width: '100%',
      '.travel-dates-information': {
        color: 'black',
        position: 'static',
        px: [0, '9px'],
        backgroundColor: 'white',
        fontSize: '16px',
        textAlign: ['initial', 'center'],
      },
      '.applyButton': {
        width: 'auto',
        borderRadius: 4,
      },
    },
    headerCss: {
      pt: [0, '20px'],
      pb: [null, '20px'],
      position: ['initial', 'relative'],
      bottom: [0, 0],
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      order: [1, 0],
      backgroundColor: 'background',
      left: 0,
      width: '100%',
      zIndex: 2,
      borderBottom: [null, '1px solid'],
      borderColor: [null, '#ECECED'],
      '.header-group-duration-dropdown': {
        flexGrow: 1,
        pl: 3,
      },
      '.travel-dates-duration-dropdown': {
        maxWidth: ['none', '350px'],
      },
    },
    headerGroupCss: {
      display: 'flex',
      flexDirection: 'column',
      pl: [0, 3],
      pb: [3, 0],
      '.label-flexible, .label-exact': {
        fontSize: [1, 2],
        mb: 2,
      },
      '.label-flexible': {
        flex: 1,
      },
    },
    navbarControlCss: {
      position: 'absolute',
      width: '100%',
      color: 'inherit',
      'button:disabled': {
        color: 'lightGreyFirst',
        cursor: 'default',
      },
    },
    weekDaysListCss: {
      borderTop: [null, '1px solid'],
      borderBottom: [null, '1px solid'],
      borderColor: ['lightGreyFourth', 'lightGreyFourth'],
      fontSize: 2,
      position: ['absolute', 'relative'],
      backgroundColor: 'white',
      width: '100%',
      top: ['55px', 'auto'],
      zIndex: 2,
      textAlign: 'center',
      color: 'secondary',
      py: '12px !important',
      '&:after': {
        content: "''",
        backgroundColor: 'lightGreyFourth',
        position: 'absolute',
        left: 3,
        bottom: 0,
        height: ['1px', 0],
        width: 'calc(100% - 32px)',
      },
    },
    travelDatesDesktop: {
      display: 'flex',
      flexDirection: 'column',
      '.date-picker': {
        '.DayPicker-Months': {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        '.DayPicker-Month': {
          width: '48%',
        },
        '.DayPicker-Month .DayPicker-Caption': {
          ...FONT_SIZE.XS,
          textAlign: 'center',
          mb: [null, 3],
        },
        '.DayPicker-Weekday': {
          color: 'secondary',
          py: [null, '13px'],
          fontSize: [null, 1],
        },
      },
    },
    travelDatesMobile: {
      display: 'flex',
      flexDirection: 'column',
      '.applyButton': {
        mb: 3,
        mt: 0,
        width: '100%',
        borderRadius: 4,
      },
    },
  }),
  travelRooms: sxGroup({
    closeButton: {},
    tooltipWrapper: {
      position: 'absolute',
      '.tooltip, .tooltipArrow': {
        visibility: 'visible',
        zIndex: 2,
        top: '-65px',
        left: '24px',
      },
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'background',
      minHeight: 'min-content',
      position: 'relative',
      p: 3,
      '.notification-heading': {
        color: 'black',
        borderRadius: '4px 4px 0 0',
      },
      '.warning-notification-title': FONT_SIZE.S,
      '.notification-body': {
        ...FONT_SIZE.MEDIUM,
        borderRadius: '0 0 4px 4px',
        border: '1px solid',
        borderColor: 'warning',
      },
      '.numeric-input-label': FONT_SIZE.LARGE,
      '&:after': {
        content: ['', "''"],
        backgroundColor: 'lightGreyFirst',
        position: 'absolute',
        left: 3,
        bottom: 0,
        height: '1px',
        width: 'calc(100% - 32px)',
      },
      pb: [2, 3],
      '.adults, .children': {
        mb: 3,
      },
      '> *:not(:last-child)': {
        mb: 3,
      },
      '.room-1, .room-2': {
        '> *:not(:last-child)': {
          mb: 3,
        },
      },
      '.rooms-label-block': {
        display: 'flex',
        alignItems: 'center',
        '.icon': {
          width: '16px',
          height: '16px',
          ml: 3,
        },
      },
      '.children-DOB-Wrapper': {
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      },
    },
    childrenDOBWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      ':last-child': {
        mb: 3,
      },
      '.childrenBirthTitle': FONT_SIZE.SMALL,
      '.childrenBirthInput': {
        flex: '1 0 auto',
        maxWidth: ['120px', '135px'],
      },
    },
    manualTravelersInput: {
      borderTop: '1px solid',
      paddingTop: 3,
    },
    applyButton: {
      minHeight: 'min-content',
      position: ['sticky', 'relative'],
      alignSelf: 'flex-end',
      width: ['calc(100% - 32px)', 'auto'],
      mt: ['auto', 4],
      mx: 3,
      mb: 3,
      bottom: 'env(safe-area-inset-bottom)',
      left: 0,
      borderRadius: 4,
    },
  }),
  climate: sxGroup({
    chartContainer: {
      height: 200,
    },
    chartsWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      mb: [0, '90px'],
    },
    preparedChart: {
      pt: [0, 4],
      px: [4, 7],
      pb: ['40px', 4],
      borderTop: [null, null, '1px solid'],
      borderTopColor: [null, null, 'borderColor'],
      width: ['100%', '50%', '50%'],
      '&:nth-child(1), &:nth-child(3)': {
        borderRight: [null, null, '1px solid'],
        borderRightColor: [null, null, 'borderColor'],
      },
      '&:nth-child(3), &:nth-child(4)': {
        borderBottom: [null, null, '1px solid'],
        borderBottomColor: [null, null, 'borderColor'],
      },
      '.chart-label': {
        ...FONT_SIZE.LARGE,
        pb: [4],
      },
      '.label': {
        ...FONT_SIZE.SMALL,
      },
      '.recharts-cartesian-axis-tick-value': {
        ...FONT_SIZE.SMALL,
      },
    },
  }),
  product: sxGroup({
    productDescriptionWrap: {
      background: 'white',
      margin: [4, 0],
      '.block': {
        p: {
          p: 0,
        },
        mb: [4, 5],
        ...FONT_SIZE.LARGE,
        h3: {
          ...FONT_SIZE.S,
          p: 0,
          marginBottom: 2,
        },
      },
      '.lead-text': {
        ...FONT_SIZE.LARGE_BOLD,
        p: {
          p: 0,
        },
        mb: [4, 5],
      },
    },
    featuresListWrapper: {
      borderTop: '1px solid',
      borderColor: 'borderColor',
      pt: 3,
      pb: 2,
      '.features-list': {
        ...FONT_SIZE.LARGE,
      },
      '.features-item': {
        mb: 1,
      },
      '.feature-icon-wrapper': {
        verticalAlign: [`sub`, `middle`],
        mr: 2,
      },
    },
    featuresHeadingWrapper: {
      display: 'flex',
      alignItems: 'center',
      mb: 3,
      '.icon': {
        mr: 2,
      },
      '.features-heading': {
        ...FONT_SIZE.XS,
      },
    },
  }),
  destinationAttributes: sxGroup({
    ratingTextStyles,
    attributeWrap: {
      pt: 3,
      pb: [4, 3],
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderColor: 'lightGreyFirst',
      mb: '-1px',
      letterSpacing: '0.7px',
    },
    attributeItemWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,
      mb: [0, 3],
      ...FONT_SIZE.XS,
      '.attributeName': {
        ...ratingTextStyles,
        '.icon': {
          verticalAlign: 'middle',
        },
      },
    },
    attributeDetailsList: {
      p: 0,
      pt: [3, 0],
      ...FONT_SIZE.LARGE,
    },
    attributeDetailWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      pr: [4, 0],
      '&:not(:last-child)': {
        mb: [2, 1],
      },

      '.attributeDetailName': {
        ...ratingTextStyles,
      },
      '.attributeRatingWrap': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    attributeDetailWrapMobile: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      pr: 4,
      mb: 2,

      '.attributeDetailName': {
        ...ratingTextStyles,
      },
      '.attributeRatingWrap': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    attributeWrapMobile: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,

      '.attributeName': {
        ...ratingTextStyles,
        fontWeight: 'bold',
      },
      '.ratingWrap': {
        display: 'flex',
        flex: '0 0 auto',
        ml: 2,
      },
    },
    destinationRatingStyles: {
      backgroundColor: 'black',
      height: ['19px', '15px'],
      width: ['19px', '15px'],
      borderRadius: 'circle',
      '&:not(:last-child)': {
        mr: 1,
      },
    },
    destinationRatingSubStyles: {
      backgroundColor: 'secondary',
      height: ['14px', '14px', '15px'],
      width: ['14px', '14px', '15px'],
      borderRadius: 'circle',
      '&:not(:last-child)': {
        mr: 1,
      },
    },
    destinationRemainRatingStyles: {
      backgroundColor: 'lightGreyFirst',
    },
    destinationRemainRatingSubStyles: {
      backgroundColor: 'white',
      border: '1px solid',
      borderColor: 'secondary',
    },
  }),
  doubleRange: sxGroup({
    rangeInputHeader: {
      p: 3,
      pr: 4,
      '.price-range-input-label': {
        mb: 2,
        fontSize: [1, 2],
        color: 'secondary',
      },
    },
    rangeInputWrapper: {
      '.range-input-text': {
        color: 'darkGray',
        fontSize: [1, 2],
      },
    },
    sliderWrapper: {
      position: 'relative',
      height: '32px',
    },
  }),
  greetings: sx({
    ...FONT_SIZE.M,
    mb: [3, 0],
    px: [2, 0],
    '.name': {
      pl: 2,
    },
  }),
  myAccount: sxGroup({
    profile: {
      formWrapper: {
        pb: 3,
      },
      formHeading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        fontSize: [2, '20px'],
        lineHeight: '30px',
        mt: [3, 5],
        py: '12px',
        color: 'black',
      },
      infoText: {
        width: '100%',
        maxWidth: '800px',
        color: 'textGray',
        fontSize: 1,
        mt: 1,
      },
      formLayoutWrapper: {
        width: '100%',
        display: 'grid',
        gap: 3,
        mt: 3,
        '.row': {
          display: 'grid',
          gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
          gap: ['20px', 3],
          p: 0,
        },
        '.row.password': {
          gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
        },
        '.form-field': {
          p: 0,
          '.input': {
            height: '44px',
            pl: 3,
            py: 0,
            fontSize: '18px',
            bg: 'transparent',
            color: 'black',
            cursor: 'text',
            borderRadius: '5px',
            '&:disabled': {
              bg: 'lightGreySixth',
              color: 'darkGray',
              opacity: 1,
              cursor: 'default',
              borderRadius: '0px',
            },
          },
          '.chevron': {
            width: '16px',
            height: '16px',
            bottom: '14px',
          },
          '.status-icon, .input-icon': {
            display: 'none',
          },
          '.label, .label span': {
            whiteSpace: 'nowrap',
            color: 'black',
            p: 0,
          },
          '.error': {
            mt: 1,
            fontSize: '14px',
          },
        },
        '.form-field.half-width': {
          width: '50%',
        },
        '.form-field.salutation': {
          display: 'flex',
          flexDirection: ['column', 'row'],
          gap: ['10px', '20px'],
          '.input[disabled]:checked ~ .customRadioInput': {
            backgroundColor: 'darkGray',
          },
          '.radio-group-container': {
            gap: '20px',
            '.label': {
              m: 0,
              p: 0,
            },
          },
        },
        '.agency': {
          width: ['100%', '50%'],
          '.input': {
            borderRadius: '0px',
          },
        },
        '.agency.success': {
          '.status-icon': {
            display: 'block',
            bottom: '-2px',
          },
          '.input': {
            borderColor: 'success',
          },
        },
      },
      submitButton: {
        bg: 'transparent',
        p: '8px 24px',
        width: ['100%', 'fit-content'],
        border: '1px solid',
        borderColor: 'black',
        borderRadius: '4px',
        justifySelf: 'end',
        '.button-text': {
          color: 'black',
          fontSize: '18px',
          fontFamily: '"Arial",sans-serif',
        },
        ':hover:not([disabled]),:focus:not([disabled]),:focus-visible:not([disabled])': {
          bg: 'black',
          border: '1px solid',

          '.button-text': {
            color: 'white',
          },
        },
      },
      actionButton: {
        width: ['100%', 'auto'],
        ml: 'auto',
        p: '9px 24px',
        textAlign: 'center',
        flexShrink: 0,
        whiteSpace: ['normal', 'nowrap'],
        bg: 'transparent',
        border: '2px solid',
        borderColor: colors.primary,
        color: colors.primary,
        fontWeight: 400,
        fontSize: '18px',
        '&:hover': {
          [media.tablet]: {
            bg: colors.interactivePrimary,
            color: 'white',
          },
        },
        '&:focus': {
          bg: colors.interactivePrimary,
          color: 'white',
        },
        '&:disabled': {
          border: 'transparent',
        },
        ...buttons[E_BUTTON_TYPE.SECONDARY],
      },
      externalAuthMessageWrapper: {
        maxWidth: '800px',
        color: 'textGray',
        fontSize: 1,
        lineHeight: '24px',
        a: {
          color: 'primary',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    logoutButton: {
      color: 'defaultText',
      fontWeight: 'normal',
      '.icon': {
        ml: 2,
      },
      '&:hover, &:focus': {
        color: 'primary',
        textDecoration: 'none',
      },
    },
    accountLink: {
      display: 'flex',
      justifyContent: 'space-between',
      ...FONT_SIZE.LINK_LARGE,
      textAlign: 'left',
      width: ['100%', 'auto'],
      p: [2, 0],
      borderTop: ['1px solid', 'none'],
      borderBottom: ['1px solid', 'none'],
      borderColor: 'borderColor',
      mb: ['-1px', 0],
      borderRadius: 0,
    },
    successMessage: {
      fontSize: [2, '18px'],
    },
    successMessageBullets: {
      listStyle: 'disc',
      ml: '12px',
      pt: '10px',
      fontSize: [2, '14px'],
    },
  }),
  checkout: sxGroup({
    checkoutWaitingScreenWrapper: {
      h3: {
        maxWidth: ['100%', '80%'],
        mx: [3, 'auto'],
        ...FONT_SIZE.M,
        textAlign: 'center',
        letterSpacing: '1.4px',
        mb: '12px',
        '@media screen and (max-width: 390px)': {
          mb: 2,
          mt: '-10px',
        },
      },
      '.product-details': {
        display: 'flex',
        flexDirection: ['column', 'row'],
        gap: ['0px', 3],
        justifyContent: 'center',
        alignItems: ['flex-start', 'center'],
        borderBottom: `1px solid ${baseColors.gray[1]}`,
        mb: [3, 4],
        pb: [3, 4],
        px: [3, 5],
        color: colors.darkGray,
        '.product-name': {
          ...FONT_SIZE.XS,
          [media.mobile]: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '90vw',
          },
        },
        '& div': {
          color: colors.darkGray,
        },
        '& div:not(:last-child):after': {
          content: "'/'",
          ml: 2,
        },
        '.breadcrumb-list': {
          [media.mobile]: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '90vw',
          },
        },
      },
      span: {
        borderTop: '2px dashed',
        borderColor: 'primary',
        height: '6px',
        flexGrow: 1,
        marginLeft: ['-40px', '-50px'],
      },
      '.loading-animation-wrapper': {
        textAlign: 'center',
        lineHeight: '0',
        mb: '28px',
        '@media screen and (max-width: 390px)': {
          mb: 3,
        },
        '.dots': {
          width: '12px',
          height: '12px',
          borderRadius: '50%',
          mx: 1,
          display: 'inline-block',
          backgroundColor: '#616E80',
          opacity: '1',
          animation: 'anim 3s infinite',
        },
        '.dots:nth-child(2)': {
          opacity: '0.2',
          animationDelay: '0.5s',
        },
        '.dots:nth-child(3)': {
          opacity: '0.2',
          animationDelay: '1s',
        },
        '.dots:nth-child(4)': {
          opacity: '0.2',
          animationDelay: '1.5s',
        },
        '.dots:nth-child(5)': {
          opacity: '0.2',
          animationDelay: '2s',
        },
        '.dots:nth-child(6)': {
          opacity: '0.2',
          animationDelay: '2.5s',
        },
        '@keyframes anim': {
          '0%': {
            opacity: '1',
          },
          '15%': {
            opacity: '1',
          },
          '100%': {
            opacity: '0.2',
          },
        },
      },
      '.subtitle-text': {
        ...FONT_SIZE.S,
        letterSpacing: [null, '0.7px'],
        mb: [2, 3],
        px: [3, 5],
        variant: 'text.richText',
        textAlign: 'center',
      },
      '.rich-text': {
        variant: 'text.richText',
        px: [3, 5],
        borderBottom: `1px solid ${baseColors.gray[1]}`,
        mb: [3, 4],
        pb: [3, 4],

        ul: {
          display: 'flex',
          flexDirection: ['column', 'row'],
          gap: 3,
          '@media screen and (max-width: 390px)': {
            gap: 2,

            li: {
              '&:nth-child(2)': {
                display: 'none',
              },
            },
          },

          li: {
            listStyle: 'none',
            pl: ['30px', '35px'],
            ml: '0px',
            position: 'relative',
            flex: '1',
            ...FONT_SIZE.LARGE,
            '&:before': {
              content: '""',
              position: 'absolute',
              left: '0',
              width: '24px',
              height: '24px',
              backgroundImage: 'url("/images/svg/Lock.svg")',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            },
            '&:nth-child(1):before': {
              backgroundImage:
                'url("/images/svg/Headphones-CustomerSupport.svg")',
            },

            '&:nth-child(2):before': {
              backgroundImage: 'url("/images/svg/Time-TwentfourHours.svg")',
            },

            '&:nth-child(3):before': {
              backgroundImage: 'url("/images/svg/Lock.svg")',
            },
          },
        },
      },
      '.payment-methods': {
        px: [3, 5],
        '> div': {
          border: 'none',
          pl: 0,
          span: {
            display: 'none',
          },
          div: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: [2, 4],
            '@media screen and (max-width: 390px)': {
              gap: 1,
            },
            a: {
              mr: 0,
            },
          },
        },
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: ['wrap', 'nowrap'],
      mx: 0,
      '.img-wrap': {
        width: ['100%', '532px'],
        mb: 3,
        px: [3, null],
        flexShrink: [null, '0'],
        alignSelf: 'center',
        '@media screen and (max-width: 390px)': {
          mb: 2,
        },
        '& .checkout-waiting-screen-img': {
          height: ['175px', '290px'],
          width: '100%',
          objectFit: 'cover',
          display: 'block',
          '@media screen and (max-width: 390px)': {
            height: '130px',
          },
        },
      },
    },
    checkoutNoResults: {
      padding: '0',
      '.title': {
        ...FONT_SIZE.S,
        mb: 2,
      },
      p: {
        ...FONT_SIZE.LARGE,
        mb: 4,
      },
      button: {
        width: '100%',
      },
    },
    checkoutResultsWrapper: {
      '.title': {
        ...FONT_SIZE.S,
        mb: 3,
      },
      p: {
        mb: 2,
        ...FONT_SIZE.LARGE,
      },
    },
    flightSectionWrapper: {
      px: [0, 3],
      mb: 2,
    },
    checkoutResultsFlex: {
      display: 'flex',
      justifyContent: 'flex-end',
      pt: [3, 2],
      '.link-btn': {
        ...FONT_SIZE.BUTTON,
        mr: 4,
      },
    },
    checkoutWaitingScreenModal: {
      '.modal-header': {
        borderBottom: '0',
        marginTop: [3, 5],
        position: ['relative', 'absolute'],
        right: [3, 5],
        padding: '0',
      },
      '.modal-wrapper': {
        position: 'relative',
        maxWidth: [null, '970px'],
        borderRadius: 'default',
        mx: [4, 0],
        alignSelf: 'center',
        maxHeight: '100vh',
      },
      '.modal-body': {
        pt: [2, '32px'],
        pb: [3, '32px'],
      },
    },
    checkoutNoResultsModal: {
      '.modal-header': {
        display: 'none',
      },
      '.modal-wrapper': smallModalWrapper,
      '.modal-body': commonModalBody,
    },
    checkoutResultsModal: {
      '.modal-header': {
        display: 'none',
      },
      '.modal-wrapper': smallModalWrapper,
      '.modal-body': commonModalBody,
    },
  }),
  boosters: sxGroup({
    boosterWrap: {
      display: 'flex',
      width: '100%',
      padding: [`0 ${space[2]}`, 0],
      marginBottom: space[2],
      backgroundColor: colors.accentColor,
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      borderRadius: radii.default,
      color: 'black',
      '.booster-text': {
        ...FONT_SIZE.S,
        pl: [2, '30px'],
        pr: 2,
        py: [2, 3],
        variant: 'text.richText',
        'p:last-child': {
          mb: 0,
        },
        a: {
          textDecoration: 'underline',
          textDecorationColor: colors.linkUnderlineColor,
          textUnderlineOffset: '4px',
          color: colors.black,
          transition: 'none',
          transform: 'translate(0)',
          '&:hover,&:focus': {
            color: colors.black,
            textDecorationColor: 'black',
            transform: 'scale(1)',
          },
        },
      },
    },
  }),
  mediaItem: sxGroup({
    cardContent: {
      px: 4,
      pb: 4,
      color: 'white',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      textAlign: 'center',
      '.card-title': {
        ...FONT_SIZE.M,
      },
      '.card-subtitle': {
        ...FONT_SIZE.LARGE,
        textDecoration: 'underline',
        textDecorationColor: colors.linkUnderlineColorWhite,
        textUnderlineOffset: '4px',
        color: colors.white,
        transition: 'none',
        transform: 'translate(0)',
        '&:hover,&:focus': {
          color: colors.white,
          textDecorationColor: colors.white,
          transform: 'scale(1)',
        },
      },
    },
  }),
  lastSeenHotels: sxGroup({
    lastSeenHotelsWrapper: {
      py: '40px',
      px: ['24px', 0],
    },
    lastSeenHotelsTitle: {
      ...FONT_SIZE.M,
      mb: [3, 0],
    },
    lastSeenHotelsCards: {
      display: 'flex',
      gap: 3,
      flexDirection: ['column', 'row'],
    },
    lastSeenHotelItemRating: {
      marginBottom: 1,
      color: 'primary',
      '.rating': {
        marginRight: '3px',
      },
      '.remainRating': {
        marginRight: '3px',
        color: 'lightGreyFirst',
      },
    },
    lastSeenHotelItemImage: {
      width: '76px',
      overflow: 'hidden',
      objectFit: 'cover',
      borderRadius: '4px',
      flexShrink: '0',
      cursor: 'pointer',
      img: {
        height: '100%',
        width: 'auto',
        maxWidth: 'fit-content',
      },
    },
    lastSeenHotelItemCard: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: 'white',
      borderRadius: '5px',
      border: '1px solid',
      borderColor: 'lightGreyFirst',
      height: '100%',
      mt: [0, 3],
      cursor: 'pointer',
      ':hover': {
        color: 'inherit',
      },
    },
    lastSeenHotelItemWrap: {
      display: 'flex',
      flexDirection: 'column',
      width: ['100%', '33%'],
    },
    lastSeenHotelItemContent: {
      ...FONT_SIZE.SMALL,
      padding: 3,
      color: 'darkGray',
      overflow: 'hidden',
      width: '100%',
      '.title': {
        ...FONT_SIZE.LARGE_BOLD,
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: 'black',
      },
    },
  }),
});

export {
  baseColors,
  colors,
  shadows,
  transition,
  media,
  fontSizes,
  space,
  radii,
};

// @ts-ignore
export default theme;
