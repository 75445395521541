import 'routes.config';
import compose from 'lodash/fp/compose';
import { AppProps, NextWebVitalsMetric } from 'next/app';
import React from 'react';
import { createBootstrapApp } from '@hotelplan/boot.web';
import { appWithClarity } from '@hotelplan/libs.clarity';
import {
  setSentryFeaturesConfig,
  useAppSentryConfig,
} from '@hotelplan/libs.error-tracking';
import { appWithFeatureToggle } from '@hotelplan/libs.feature-toggle';
import {
  appWithGss,
  mapFormStateToGlobalSearchState,
  mapGlobalSearchStateToFormState,
} from '@hotelplan/libs.gss';
import { trackWebVitals } from '@hotelplan/libs.tracking';
import {
  mapFlightGlobalSearchStateToFormState,
  mapFormStateToFlightGlobalSearchState,
} from 'components/domain/fgss';
import Layout from 'components/domain/layout/Layout';
import { apolloTypePolicies } from 'config/apolloTypePolicies';
import { FeatureList } from 'config/pageConfig.constants';
import nextI18NextConfig from 'next-i18next.config.js';
import logo from 'public/images/HP-Sharing-Fallback.png';
import theme from 'theme';
import { GlobalStyle } from 'theme/globalStyles';
import '@hotelplan/libs.router-config'; // it has global patch for History
import 'intersection-observer';
import 'url-polyfill';
import 'custom-event-polyfill';
import 'core-js/features/object/from-entries';
import 'public/css/fonts.css';
import 'normalize.css';
import 'react-day-picker/lib/style.css';

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (metric.label === 'web-vital') {
    trackWebVitals({
      payload: {
        id: metric.id,
        name: metric.name,
        value: metric.value,
        valueRounded: Math.round(
          metric.name === 'CLS' ? metric.value * 1000 : metric.value
        ),
      },
    });
  }
}

const App = compose(
  appWithFeatureToggle({
    featureUpdatesEndpoint: `/api/feature-toggle/updates`,
    onGetConfig(config) {
      setSentryFeaturesConfig(config);
    },
  }),
  appWithGss({
    gssEndpoint: `/api/gss`,
    mapAppGssToIntermediateGss: mapFormStateToGlobalSearchState,
    mapAppFgssToIntermediateFgss: mapFormStateToFlightGlobalSearchState,
    mapIntermediateGssToAppGss: mapGlobalSearchStateToFormState,
    mapIntermediateFgssToAppFgss: mapFlightGlobalSearchStateToFormState,
  }),
  appWithClarity
)(
  createBootstrapApp({
    version: process.env.VERSION,
    // Theme object, using shared `DefaultTheme` type from `styled-components`
    theme,
    // Global styles component created by `styled-components` fn `createGlobalStyle`
    GlobalStyle,
    // next-i18next config object, optional - could be collected from env variables
    i18nextConfig: nextI18NextConfig,
    // Apollo TypePolicies cache configuration
    apolloTypePolicies,
    metaOptions: {
      favIconDefault: `/images/favicon.ico`,
      favIconSvg: `/images/favicon.svg`,
      appleTouchIcon: `/images/apple-touch-icon.png`,
      webManifest: `/manifest.webmanifest`,
      favIconColor: theme.colors.primary,
    },
    pageViewOptions: {
      exposeFeatures: true,
      splitPageViewEventFeatureToggle: FeatureList.SPLIT_PAGEVIEW_EVENT,
    },
    // app based side effects, e.g. Sentry config initialisation
    useSideEffects({ user }) {
      useAppSentryConfig({
        user,
      });
    },
    // layout component, children will be provided automatically
    getLayoutComponent({ pageProps }) {
      if (pageProps?.withoutLayout) {
        return <React.Fragment />;
      }

      return (
        <Layout
          disableFooter={pageProps.disableFooter}
          lastSeenHotels={pageProps.lastSeenHotels}
        />
      );
    },
    openGraphOptions: {
      enable: true,
      fallback: locale => ({
        title: {
          ['de']: `Ferien mit Hotelplan`,
          ['fr']: `Vacances avec Hotelplan`,
        }[locale],
        image: logo.src,
      }),
    },
    authNextOptions: {
      logoutCallback: () => {
        window.location.reload();
      },
    },
  })
);

// prevent unnecessary renders
export default React.memo<AppProps>(App, (prev, next) => {
  return (
    prev.Component === next.Component &&
    prev.pageProps === next.pageProps &&
    prev.router.route === next.router.route
  );
});
