import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
export type ContactsFragment = {
  __typename?: 'ContactInformation';
  emailAddress?: string | null;
  emailTitle?: string | null;
  mainTitle?: string | null;
  openingHours?: string | null;
  phoneNumber?: string | null;
  phoneTitle?: string | null;
};

export const ContactsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contacts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactInformation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openingHours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneTitle' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
