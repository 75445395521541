import { useMutation } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { Dispatch, FC, useCallback, useEffect } from 'react';
import { DeviceType, PageLanguage } from '@hotelplan/graphql.types';
import { useRequestContext } from '@hotelplan/libs.context.req-ctx';
import {
  ViewedProductAddedToWishlistDocument,
  ViewedProductAddedToWishlistMutationVariables,
} from 'graphql/viewed-product/ViewedProductAddedToWishlist.generated';
import {
  ViewedProductCheckoutDocument,
  ViewedProductCheckoutMutationVariables,
} from 'graphql/viewed-product/ViewedProductCheckout.generated';
import {
  ViewedProductVisitedDocument,
  ViewedProductVisitedMutationVariables,
} from 'graphql/viewed-product/ViewedProductVisited.generated';
import {
  TViewedProductContextAction,
  TViewedProductEvent,
  ViewedProductContextActionType,
  ViewedProductEventType,
} from './ViewedProductContext.types';

interface IViewedProductContextMutationItemProps<TVariables> {
  event: TViewedProductEvent;
  documentNode: DocumentNode;
  variablesMapper: (event: TViewedProductEvent) => TVariables;
  dispatch: Dispatch<TViewedProductContextAction>;
}

function ViewedProductContextMutationItem<TVariables>(
  props: IViewedProductContextMutationItemProps<TVariables>
): ReturnType<FC> {
  const { event, documentNode, variablesMapper, dispatch } = props;

  const [mutation, { data, loading }] = useMutation(documentNode, {
    variables: variablesMapper(event),
  });

  useEffect(() => {
    if (!data && !loading) {
      mutation();
    } else if (data && !loading) {
      dispatch({
        type: ViewedProductContextActionType.REMOVE,
        event,
      });
    }
  }, [data, loading]);

  return null;
}

interface IViewedProductContextQueueItem {
  event: TViewedProductEvent;
  dispatch: Dispatch<TViewedProductContextAction>;
}

const ViewedProductContextQueueItem: FC<IViewedProductContextQueueItem> = ({
  event,
  dispatch,
}) => {
  const requestContext = useRequestContext<DeviceType, PageLanguage>();

  const variablesMapper = useCallback(
    (productEvent: TViewedProductEvent) => ({
      productId: productEvent.productId,
      context: requestContext,
    }),
    [requestContext]
  );

  switch (event.type) {
    case ViewedProductEventType.VISITED:
      return (
        <ViewedProductContextMutationItem<ViewedProductVisitedMutationVariables>
          event={event}
          documentNode={ViewedProductVisitedDocument}
          variablesMapper={variablesMapper}
          dispatch={dispatch}
        />
      );

    case ViewedProductEventType.ADDED_TO_WISHLIST:
      return (
        <ViewedProductContextMutationItem<ViewedProductAddedToWishlistMutationVariables>
          event={event}
          documentNode={ViewedProductAddedToWishlistDocument}
          variablesMapper={variablesMapper}
          dispatch={dispatch}
        />
      );

    case ViewedProductEventType.CHECKOUT:
      return (
        <ViewedProductContextMutationItem<ViewedProductCheckoutMutationVariables>
          event={event}
          documentNode={ViewedProductCheckoutDocument}
          variablesMapper={variablesMapper}
          dispatch={dispatch}
        />
      );
  }
};

export default ViewedProductContextQueueItem;
