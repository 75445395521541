import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
export type WishlistItemFragment = {
  __typename?: 'WishlistItem';
  id: string;
  inWishlist: boolean;
};

export const WishlistItemFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'wishlistItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WishlistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inWishlist' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
