import React from 'react';

const Fonts: React.FC = () => {
  return (
    <>
      <link
        key="font-p-m2"
        rel="preload"
        href="/fonts/PPAgrandir-Medium.woff2"
        as="font"
        type="font/woff2"
        crossOrigin=""
      />
      <link
        key="font-p-r2"
        rel="preload"
        href="/fonts/PPAgrandir-Regular.woff2"
        as="font"
        type="font/woff2"
        crossOrigin=""
      />
    </>
  );
};

export default Fonts;
