/* eslint-disable @typescript-eslint/no-unused-vars,i18next/no-literal-string */
import {
  FieldMergeFunction,
  FieldPolicy,
  TypePolicies,
  TypePolicy,
} from '@apollo/client';
import max from 'lodash/max';
import {
  AgencyAutocomplete,
  AutocompleteComponent,
  HistoryProductResponse,
  HistoryUnionResponse,
  Page,
} from '@hotelplan/graphql.types';
import {
  WishlistItemFragment,
  WishlistItemFragmentDoc,
} from 'graphql/wishlist/WishlistItemFragment.generated';
import { KeyArgsReducer } from './apolloTypePolicies.types';

const possibleTypeNames = [
  `AccountContext`,
  `AccountLoginPage`,
  `AccountMutationResponse`,
  `AccountMyProfilePage`,
  `AccountPage`,
  `AccountPersonalDataResponse`,
  `AddressDetails`,
  `AddTravelCompanionResponse`,
  `Agency`,
  `AgencyAppointmentResponse`,
  `AgencyAutocomplete`,
  `AgencyContact`,
  `AgencyContactPhone`,
  `AgencyOverviewPageContext`,
  `AgencyRecommendationGroup`,
  `Airline`,
  `Airport`,
  `AuthMethod`,
  `AuthMethodListResponse`,
  `AutocompleteComponent`,
  `BD4TravelRecommendation`,
  `BD4TravelRecommendationInfo`,
  `BD4TravelRecommendationTracking`,
  `BlogArticleRecommendationItem`,
  `BoardType`,
  `BookingBoardType`,
  `BookingDetails`,
  `BookingDetailsResponse`,
  `BookingExtraService`,
  `BookingHotelRoom`,
  `BookingItem`,
  `BookingResponse`,
  `BookingRoomType`,
  `BookingTransfer`,
  `Booster`,
  `BrandBox`,
  `BrandsComponent`,
  `Breadcrumb`,
  `BreadcrumbsComponent`,
  `BusinessUnit`,
  `Catalog`,
  `CatalogOrderResponse`,
  `CatalogOverviewContext`,
  `CheckboxFilterComponent`,
  `ClimateChart`,
  `ClimateChartEntry`,
  `ClimateComponent`,
  `ContactForm`,
  `ContactFormResponse`,
  `ContactInformation`,
  `ContactPageContext`,
  `ContactRecommendationGroup`,
  `CookieDisclaimer`,
  `DeleteHistoryResponse`,
  `DoubleRangeSliderFilterComponent`,
  `EmailAdvertisingGetEmailFromTokenResponse`,
  `EmailAdvertisingGetTokenFromEmailResponse`,
  `EmailAdvertisingRecommendationGroup`,
  `EmailAdvertisingRevocationConfirmationPageContext`,
  `EmailAdvertisingRevocationPageContext`,
  `EmailAdvertisingRevocationResponse`,
  `EmailAdvertisingStaticContent`,
  `EmployeeBox`,
  `EmployeesComponent`,
  `EmployeeSocialProfile`,
  `ExactTravelPeriod`,
  `ExternalLink`,
  `ExternalMediaItem`,
  `FaqComponent`,
  `FilterComponent`,
  `FilterItem`,
  `FlexibleTravelPeriod`,
  `Flight`,
  `FlightBaggageInfo`,
  `FlightCheckoutComponent`,
  `FlightDestinationInfo`,
  `FlightHistoryResponse`,
  `FlightHomeContext`,
  `FlightHomeRecommendationGroup`,
  `FlightOffer`,
  `FlightPartition`,
  `FlightRecommendationWithoutPriceItem`,
  `FlightRecommendationWithPriceItem`,
  `FlightSearchControlComponent`,
  `FlightSegment`,
  `FlightSrlComponent`,
  `FlightSrlContainer`,
  `FlightSrlContext`,
  `FlightSrlRecommendationGroup`,
  `FlightStopOverDuration`,
  `GeoChildComponent`,
  `GeoChildrenComponent`,
  `GeoContext`,
  `GeoCoordinates`,
  `GeoDefaultGeoRecommendationsComponent`,
  `GeoFeature`,
  `GeoFeatureGroup`,
  `GeoInfoComponent`,
  `GeoLocation`,
  `GeoObject`,
  `GeoObjectRecommendationGroupComponent`,
  `GeoOverviewChildComponent`,
  `GeoOverviewContext`,
  `GeoOverviewRecommendationGroupComponent`,
  `GeoRecommendationItem`,
  `GroupOrlItem`,
  `HelpOverlayBox`,
  `HelpOverlayBoxChat`,
  `HelpOverlayBoxContact`,
  `HelpOverlayData`,
  `HeroMediaGallery`,
  `HistoryContext`,
  `HistoryFlightRecord`,
  `HistoryProductRecord`,
  `HistorySrlRecord`,
  `HolidayFinderInfo`,
  `HolidayFinderLandingPage`,
  `HolidayFinderOffer`,
  `HolidayFinderPage`,
  `HolidayFinderPageContext`,
  `HolidayFinderProduct`,
  `HolidayFinderTracking`,
  `HolidayFinderVotingResponse`,
  `HomeContext`,
  `HomeRecommendationGroup`,
  `HomeStaticContent`,
  `HomeTitle`,
  `HotelDestinationInfo`,
  `IconMenuItem`,
  `Image`,
  `ImageMediaItem`,
  `InternalLink`,
  `LinkListComponent`,
  `LinkListItem`,
  `MapSuggestion`,
  `MarketingRecommendationItem`,
  `MediaGallery`,
  `Menu`,
  `MusicMediaItem`,
  `Mutation`,
  `NewsArticle`,
  `NewsArticlePage`,
  `NewsArticlesComponent`,
  `NewsArticlesFilter`,
  `NewsArticlesOverview`,
  `NewsArticlesOverviewPage`,
  `NewsletterConfirmationContext`,
  `NewsletterFinalizationContext`,
  `NewsletterGetEmailFromTokenResponse`,
  `NewsletterGetTokenFromEmailResponse`,
  `NewsletterRecommendationGroup`,
  `NewsletterSubscription`,
  `NewsletterSubscriptionContext`,
  `NewsletterSubscriptionResponse`,
  `NewsletterUnsubscriptionContext`,
  `NewsletterUnsubscriptionFinalizationContext`,
  `NewsletterUnsubscriptionResponse`,
  `Notification`,
  `NotificationInfo`,
  `NudgeItem`,
  `OpeningHours`,
  `OrlCheckoutComponent`,
  `OrlContext`,
  `OrlFlightAlternative`,
  `OrlGroupListComponent`,
  `OrlHistoryResponse`,
  `OrlIncludedInPriceComponent`,
  `OrlPriceExplanation`,
  `OrlPriceExplanationComponent`,
  `OrlRoom`,
  `OrlSearchContainer`,
  `OrlSingleListComponent`,
  `Page`,
  `PageB2BLoginData`,
  `PageFooterData`,
  `PageHeaderData`,
  `PageMetaData`,
  `PageNotFound404Data`,
  `PdfMediaItem`,
  `PDOItem`,
  `PdpContainer`,
  `PdpContext`,
  `PdpDescriptionComponent`,
  `PdpDestinationInfoComponent`,
  `PdpFeatureRating`,
  `PdpMapComponent`,
  `PdpMapHotel`,
  `PdpMoreOffersButton`,
  `PdpOverviewComponent`,
  `PdpPriceDateOverviewComponent`,
  `PdpRecommendationGroup`,
  `PdpTripAdvisorComponent`,
  `PhoneDetails`,
  `Price`,
  `ProductFeature`,
  `ProductFeatureGroup`,
  `ProductRecommendationItem`,
  `Query`,
  `RadiobuttonFilterComponent`,
  `ReasonsOfConfidence`,
  `ResizedImage`,
  `ResortTopHotelsComponent`,
  `RoomType`,
  `SearchControlComponent`,
  `Shift`,
  `SingleOrlItem`,
  `SingleValueFilterComponent`,
  `SliderFilterComponent`,
  `SrlContext`,
  `SrlEmptyResult`,
  `SrlGeoGroupItem`,
  `SrlGeoGroupResult`,
  `SrlGeoItem`,
  `SrlHistoryResponse`,
  `SrlMapGeoPin`,
  `SrlMapPinsComponent`,
  `SrlMapProductPin`,
  `SrlProductItem`,
  `SrlRecommendationGroupComponent`,
  `SrlResultContext`,
  `SrlSearchControlsContext`,
  `SrlSingleResult`,
  `SrlSortComponent`,
  `SrlSubGeoFilterComponent`,
  `SrlSubGeoItem`,
  `StaticContext`,
  `StaticRecommendationGroup`,
  `TextComponent`,
  `TextMenuItem`,
  `ThemeContext`,
  `ThemeOverviewContext`,
  `ThemeOverviewPage`,
  `ThemePageRecommendationGroup`,
  `ThemePreviewComponent`,
  `ThemeRecommendationItem`,
  `ThemeStaticContent`,
  `TransferDate`,
  `TransferDetailInfo`,
  `TransferFlightInfo`,
  `TransferHotelInfo`,
  `TransferInfo`,
  `TravelCompanion`,
  `TravelComponentResponse`,
  `TravelDestination`,
  `Traveller`,
  `TravellerInfo`,
  `Travellers`,
  `TravelPeriodComponent`,
  `TripAdvisorRating`,
  `TripAdvisorReview`,
  `TripAdvisorSubrating`,
  `UserFinalizationResponse`,
  `UserParamsFromTokenResponse`,
  `UserRegistrationResponse`,
  `UspBox`,
  `UspBoxesComponent`,
  `VideoMediaItem`,
  `WaitingScreen`,
  `Wishlist`,
  `WishlistActiveComponent`,
  `WishlistAddMultipleToWishlistResponse`,
  `WishlistAddToWishlistResponse`,
  `WishlistContext`,
  `WishlistMutationResponse`,
  `WishlistOffer`,
  `WishlistOfferRoom`,
  `WishlistOverviewComponent`,
  `WishlistProduct`,
  `WishlistProductItem`,
] as const;

type TypeNames = typeof possibleTypeNames[number];

// add default behaviour to all types
const possibleTypePolicies: Record<
  TypeNames,
  TypePolicy
> = possibleTypeNames.reduce(
  (policies, policyType) => ({
    ...policies,
    [policyType]: { keyFields: false, merge: true },
  }),
  {} as any
);

const addAllArgsReducer: KeyArgsReducer = (res, args) => {
  return [...res, ...Object.keys(args)].filter((v, i, a) => a.indexOf(v) === i);
};

const excludeContextArgReducer: KeyArgsReducer = res => {
  return res.filter(key => key !== 'context');
};

const addContextLanguageArgReducer: KeyArgsReducer = res => {
  return ['context', ['language'], ...res];
};

const buildFieldPolicy = (reducers: KeyArgsReducer[]): FieldPolicy => ({
  keyArgs: (args, ctx) => {
    const k = reducers.reduce((keys, reducer) => {
      return reducer(keys, args, ctx);
    }, []);
    // console.log(k);  todo: remove
    return k;
  },
});

const defaultFieldPolicy = buildFieldPolicy([
  addAllArgsReducer,
  excludeContextArgReducer,
  addContextLanguageArgReducer,
]);

function recommendationWishlistFieldPolicy(): TypePolicy {
  return {
    fields: {
      wishlistItem: (_, { cache, readField }) => {
        const id = readField(`offerId`);
        const wishlistItem = cache.readFragment<WishlistItemFragment>({
          id: `WishlistItem:${id}`,
          fragment: WishlistItemFragmentDoc,
        });

        return wishlistItem && 'id' in wishlistItem
          ? wishlistItem
          : {
              __typename: `WishlistItem`,
              id,
              inWishlist: readField(`inWishlist`),
            };
      },
    },
  };
}

function assign<K extends TypeNames>(typeName: K, policy: TypePolicy) {
  Object.assign(
    (possibleTypePolicies[typeName] = possibleTypePolicies[typeName] || {}),
    policy
  );
}

assign('Query', {
  // merge: (existing, incoming, options) => {
  //   return options.mergeObjects(existing, incoming);
  // },
  fields: {
    account: defaultFieldPolicy,
    agency: defaultFieldPolicy,
    agencyContact: defaultFieldPolicy,
    agencyOverview: defaultFieldPolicy,
    boosters: defaultFieldPolicy,
    catalogOverview: defaultFieldPolicy,
    contact: defaultFieldPolicy,
    cookieDisclaimer: defaultFieldPolicy,
    emailAdvertisingRevocation: defaultFieldPolicy,
    emailAdvertisingRevocationConfirmation: defaultFieldPolicy,
    flightHome: defaultFieldPolicy,
    flightSrl: defaultFieldPolicy,
    flightWaitingScreen: defaultFieldPolicy,
    geo: defaultFieldPolicy,
    geoOverview: defaultFieldPolicy,
    helpOverlayData: defaultFieldPolicy,
    history: defaultFieldPolicy,
    holidayFinder: defaultFieldPolicy,
    home: defaultFieldPolicy,
    newsletterConfirmation: defaultFieldPolicy,
    newsletterSubscription: defaultFieldPolicy,
    newsletterFinalization: defaultFieldPolicy,
    newsletterUnsubscription: defaultFieldPolicy,
    newsletterUnsubscriptionFinalization: defaultFieldPolicy,
    notificationData: defaultFieldPolicy,
    notificationInfoData: defaultFieldPolicy,
    orl: defaultFieldPolicy,
    pageB2BLogin: defaultFieldPolicy,
    pageFooter: defaultFieldPolicy,
    pageHeader: defaultFieldPolicy,
    pageNotFound404: defaultFieldPolicy,
    pdp: defaultFieldPolicy,
    productWaitingScreen: defaultFieldPolicy,
    srl: defaultFieldPolicy,
    static: defaultFieldPolicy,
    theme: defaultFieldPolicy,
    themeOverview: defaultFieldPolicy,
    wishlist: defaultFieldPolicy,
    wishlistItem: (_, { args: { id }, cache }) => {
      return cache.readFragment({
        id: `WishlistItem:${id}`,
        fragment: WishlistItemFragmentDoc,
      });
    },
  },
});

const autoCompleteMerge: FieldMergeFunction<AutocompleteComponent> = (
  existing,
  incoming
) => {
  if (!existing) return incoming;
  return {
    page: incoming.page,
    destinations: existing.destinations.concat(incoming.destinations),
  };
};

assign('AccountContext', {
  fields: {
    bookingDetails: {
      keyArgs: ['bookingId'],
    },
    upcomingBookings: {
      keyArgs: ['page'],
    },
    pastBookings: {
      keyArgs: ['page'],
    },
  },
});

assign('Agency', { merge: false });

const agencyAutocompleteMerge: FieldMergeFunction<AgencyAutocomplete> = (
  existing,
  incoming
) => {
  if (!existing) return incoming;
  return {
    page: incoming.page,
    status: incoming.status,
    objects: existing.objects.concat(incoming.objects),
  };
};

assign('AgencyOverviewPageContext', {
  fields: {
    autocomplete: {
      keyArgs: ['criteria', ['input']],
      merge: agencyAutocompleteMerge,
    },
  },
});

assign('BD4TravelRecommendation', recommendationWishlistFieldPolicy());

assign('FlightHomeContext', {
  fields: {
    autocomplete: {
      keyArgs: ['criteria', ['input', 'selected']],
      merge: autoCompleteMerge,
    },
  },
});

assign('FlightSrlContext', {
  fields: {
    autocomplete: {
      keyArgs: ['criteria', ['input', 'selected']],
      merge: autoCompleteMerge,
    },
  },
});

assign('GeoContext', {
  fields: {
    autocomplete: {
      keyArgs: ['criteria', ['input', 'selected']],
      merge: autoCompleteMerge,
    },
  },
});

assign('HomeContext', {
  fields: {
    autocomplete: {
      keyArgs: ['criteria', ['input', 'selected']],
      merge: autoCompleteMerge,
    },
  },
});

function createHistoryMerge<R extends { page: Page }>(
  itemsField: keyof R
): FieldMergeFunction<R> {
  return (existing, incoming) => {
    if (!existing) return incoming;
    const { resultsPerPage, pageNumber } = incoming.page;
    const fromIndex = pageNumber * resultsPerPage;
    const existingItems = (existing[itemsField] as unknown) as Array<any>;
    const incomingItems = (incoming[itemsField] as unknown) as Array<any>;
    return {
      ...incoming,
      page: {
        ...incoming.page,
        pageNumber: max([incoming.page.pageNumber, existing.page.pageNumber]),
      },
      [itemsField]: [
        ...existingItems.slice(0, fromIndex),
        ...(Array.from({ length: fromIndex - existingItems.length }).map(
          () => null
        ) as Array<any>),
        ...incomingItems,
        ...existingItems.slice(fromIndex + incomingItems.length),
      ],
    };
  };
}

assign('HistoryContext', {
  fields: {
    recentProductsWithPage: {
      keyArgs: false,
      merge: createHistoryMerge<HistoryProductResponse>('productRecords'),
    },
    recentSearchesWithPage: {
      keyArgs: false,
      merge: createHistoryMerge<HistoryUnionResponse>('recordUnions'),
    },
    recentSearchesByTravelTypeWithPage: {
      keyArgs: false,
      merge: createHistoryMerge<HistoryUnionResponse>('recordUnions'),
    },
  },
});

assign('Image', { merge: false });

assign('ImageMediaItem', { merge: false });

assign('OrlContext', {
  merge: function (existing, incoming, options) {
    const consolidated = { ...existing, ...incoming };
    const resultingMap = new Map();
    const i = 0;

    for (const item in consolidated) {
      const typename = consolidated[item].__typename;

      resultingMap.set(
        typename || item,
        [
          ...(resultingMap?.get(typename) || []),
          existing?.[item] && { [item]: existing?.[item] },
          incoming?.[item] && { [item]: incoming?.[item] },
        ].filter(Boolean)
      );
    }

    return Array.from(resultingMap.values()).reduce((agr, values) => {
      if (values.length === 1) {
        return { ...agr, ...values[0] };
      }

      const merged = values.reduce((inAgr, value) => {
        for (const key in value) {
          if (incoming[key]) {
            inAgr[key] = options.mergeObjects(
              existing?.[key] || {},
              incoming[key]
            );
          }
        }
        return inAgr;
      }, {});

      return { ...agr, ...merged };
    }, {});
  },
  fields: {
    checkout: {
      keyArgs: ['offerId', 'backUrl'],
    },
    filters: {
      keyArgs: [
        'searchCriteria',
        ['filter', 'searchControl', 'sort'],
        'travelType',
      ],
      merge: true,
    },
    priceDateOverview: {
      keyArgs: ['criteria'],
    },
    priceExplanation: {
      keyArgs: ['criteria'],
    },
    searchResult: {
      keyArgs: ['criteria'],
    },
    url: {
      keyArgs: ['criteria'],
    },
    quickHotelInfo: {
      keyArgs: ['criteria'],
    },
  },
});

assign('OrlSearchContainer', {
  fields: {
    flightAlternatives: {
      keyArgs: ['offerId'],
    },
    single: {
      keyArgs: ['groupId'],
    },
  },
});

// todo: test keyFields, merge
assign('PdpContainer', {
  // merge: (existing, incoming) => {
  //   return { ...incoming };
  // },
  // keyFields: (object, { keyObject }) => {
  //   // console.log(object, keyObject);
  //   return [];
  // },
});

assign('PdpContext', {
  fields: {
    autocomplete: {
      keyArgs: ['criteria'],
    },
    dynamicComponents: {
      keyArgs: ['criteria'],
    },
    url: {
      keyArgs: [
        'targetPage',
        'searchCriteria',
        ['searchControl', 'filter', 'sort', 'page', ['groupId']],
      ],
    },
  },
});

assign('ProductFeatureGroup', { merge: false });

assign('ProductFeature', { merge: false });

assign('ProductRecommendationItem', recommendationWishlistFieldPolicy());

assign('ResizedImage', { merge: false });

assign('SingleOrlItem', {
  fields: {
    wishlistItem: (_, { readField, cache }) => {
      const id = readField(`id`);

      const wishlistItem = cache.readFragment<WishlistItemFragment>({
        id: `WishlistItem:${id}`,
        fragment: WishlistItemFragmentDoc,
      });

      return wishlistItem && 'id' in wishlistItem
        ? wishlistItem
        : {
            __typename: `WishlistItem`,
            id,
            inWishlist: readField(`inWishlist`),
          };
    },
  },
});

assign('SrlContext', {
  fields: {
    search: {
      keyArgs: ['searchCriteria'],
    },
    searchControls: {
      merge: true,
      keyArgs: [
        'encodedCriteria',
        'travelType',
        'searchCriteria',
        [
          'filters',
          'forceSingleView',
          'groupSorting',
          // 'page', // should not have page
          'productSorting',
          'searchControl',
          'subGeoFilter',
        ],
      ],
    },
    autocomplete: {
      keyArgs: ['criteria', ['input', 'selected']],
      merge: autoCompleteMerge,
    },
  },
});

assign('SrlMapProductPin', { merge: false });

assign('SrlProductItem', {
  keyFields: ['offerId'],
  fields: {
    wishlistItem(_, { cache, readField }) {
      const id = readField(`offerId`);
      const wishlistItem = cache.readFragment<WishlistItemFragment>({
        id: `WishlistItem:${id}`,
        fragment: WishlistItemFragmentDoc,
      });

      return wishlistItem && 'id' in wishlistItem
        ? wishlistItem
        : {
            __typename: `WishlistItem`,
            id,
            inWishlist: readField(`inWishlist`),
          };
    },
  },
});

assign('SrlResultContext', {
  fields: {
    geoGroup: {
      keyArgs: ['groupId', 'page'],
    },
  },
});

assign('ThemeContext', {
  fields: {
    autocomplete: {
      keyArgs: ['criteria', ['input', 'selected']],
      merge: autoCompleteMerge,
    },
    dynamicContent: {
      keyArgs: ['searchCriteria'],
    },
    searchControl: {
      keyArgs: ['travelType'],
    },
    link: {
      keyArgs: ['searchCriteria', 'targetPageType'],
    },
  },
});

assign('ThemeOverviewContext', {
  fields: {
    autocompleteDestinations: {
      keyArgs: ['criteria', ['input', 'selected']],
      merge: autoCompleteMerge,
    },
  },
});

assign('WishlistContext', {
  fields: {
    activeOffer: {
      keyArgs: ['productId', 'searchCriteria'],
    },
    shareUrl: {
      keyArgs: ['wishlistId'],
    },
  },
});

assign('WishlistOffer', {
  fields: {
    wishlistItem: (_, { cache, readField }) => {
      const id = readField(`id`);
      const wishlistItem = cache.readFragment<WishlistItemFragment>({
        id: `WishlistItem:${id}`,
        fragment: WishlistItemFragmentDoc,
      });

      return wishlistItem && 'id' in wishlistItem
        ? wishlistItem
        : {
            __typename: `WishlistItem`,
            id,
            inWishlist: true,
          };
    },
  },
});

export const apolloTypePolicies: TypePolicies = { ...possibleTypePolicies };
