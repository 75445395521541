import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const ContainerStyles = {
  maxWidth: '1218px',
  width: '100%',
  mx: 'auto',
  px: [0, 4],
};

const Container = styled.div(sx2CssThemeFn(ContainerStyles));

export default Container;
