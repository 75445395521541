import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useSelfUpdatingRef } from '@hotelplan/libs.hooks-react';
import { optionsWithPassive } from '@hotelplan/libs.polyfills';

export interface IInactivityTimerProps {
  timeout: number;
  onIdle(): void;
}

const DEFAULT_DEBOUNCE = 250;

export function useInactivityTimer(config: IInactivityTimerProps) {
  const { onIdle, timeout } = config;

  const { start } = useIdleTimer({
    debounce: DEFAULT_DEBOUNCE,
    timeout,
    onIdle,
    startManually: true,
    startOnMount: false,
  });

  const startRef = useSelfUpdatingRef(start);

  useEffect(function initBrowserEffect() {
    function onLoad() {
      startRef.current();
    }

    document.addEventListener('click', onLoad, optionsWithPassive);
    document.addEventListener('touchstart', onLoad, optionsWithPassive);
    document.addEventListener('scroll', onLoad, optionsWithPassive);

    return function () {
      document.removeEventListener('click', onLoad);
      document.removeEventListener('touchstart', onLoad);
      document.removeEventListener('scroll', onLoad);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
