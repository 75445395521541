import React from 'react';
import { Boosters } from '@hotelplan/components.common.boosters';
import { useGetBoostersData } from './useGetBoostersData';

const BoostersBanner: React.FC = () => {
  const { boosters } = useGetBoostersData();
  return <Boosters boosters={boosters} />;
};

export default BoostersBanner;
