export enum ViewedProductEventType {
  'VISITED',
  'ADDED_TO_WISHLIST',
  'CHECKOUT',
}

export type TViewedProductEvent = {
  type: ViewedProductEventType;
  id: string;
  productId: string;
};

export interface IViewedProductContextState {
  queue: TViewedProductEvent[];
}

export interface IViewedProductContext extends IViewedProductContextState {
  trackVisited(productId: string): void;

  trackAddedToWishlist(productId: string): void;

  trackCheckout(productId: string): void;
}

export enum ViewedProductContextActionType {
  'ADD',
  'REMOVE',
}

export type TViewedProductContextAction = {
  type: ViewedProductContextActionType;
  event: TViewedProductEvent;
};
