import React from 'react';
import { CookiesDisclaimer } from '@hotelplan/components.common.cookies-disclaimer';
import { useGetCookieDisclaimer } from './useGetCookieDisclaimer';

const CookiesConsentBanner: React.FC = () => {
  const { cookieDisclaimerText } = useGetCookieDisclaimer();

  return cookieDisclaimerText ? (
    <CookiesDisclaimer text={cookieDisclaimerText} />
  ) : null;
};

export default CookiesConsentBanner;
